import React from 'react';
import styled from 'styled-components';

const StyledBlurpsTop = styled.section`
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 50px;
    @media screen and (max-width: 850px) {
        flex-direction: column;
        width: 90%;
    }
    @media screen and (max-width: 650px) {
        padding-top: 0;
        padding-bottom: 20px;
    }
    .blurp_top {
        display: flex;
        width: 28%;
        @media screen and (max-width: 850px) {
            width: 90%;
            padding-top: 30px;
        }
        img {
            height: 30px;
            padding-right: 10px;
            @media screen and (max-width: 650px) {
                height: 25px;
            }
        }
        h2 {
            font-size: 2.4rem;
            padding-bottom: 15px;
            @media screen and (max-width: 650px) {
                font-size: 1.8rem;
            }
        }
        p {
            font-size: 1.6rem;
            line-height: 1.7;
            font-style: italic;
            @media screen and (max-width: 650px) {
                font-size: 1.4rem;
            }
        }
    }
`
const BlurpsTop = props => {
    const { category } = props;
    return (
        <StyledBlurpsTop>
            <div className='blurp_top'>
                <img src={category.top_blurp_img.guid} alt="" />
                <div>
                    <h2>{category.top_blurp_title_1}</h2>
                    <p>{category.top_blurp_description_1}</p>
                </div>
            </div>
            <div className='blurp_top'>
                <img src={category.top_blurp_img.guid} alt="" />
                <div>
                    <h2>{category.top_blurp_title_2}</h2>
                    <p>{category.top_blurp_description_2}</p>
                </div>
            </div>
            <div className='blurp_top'>
                <img src={category.top_blurp_img.guid} alt="" />
                <div>
                    <h2>{category.top_blurp_title_3}</h2>
                    <p>{category.top_blurp_description_3}</p>
                </div>
            </div>
        </StyledBlurpsTop>
    )
}

export default BlurpsTop;