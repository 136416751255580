import { combineReducers } from 'redux';

import * as types from '../../actions/actionTypes'

const slugList = (state = [], action) => {
    switch (action.type) {
        case types.ADD_PRODUCTS:
            return action.payload.result;
        default:
            return state;
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_PRODUCTS:
            return action.payload.entities.products;
        default:
            return state;
    }
}

const images = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_PRODUCTS:
            return action.payload.entities.images;
        default:
            return state;
    }
}

const metaData = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_PRODUCTS:
            return action.payload.entities.metaData;
        default:
            return state;
    }
}

const tags = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_PRODUCTS:
            return action.payload.entities.tags;
        default:
            return state;
    }
}

const products = combineReducers({
    slugList,
    data,
    images,
    metaData,
    tags
})

export default products;