import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../components/categoryPage/Header';
import BlurpsTop from '../components/categoryPage/BlurpsTop';
import Breadcamp from '../components/categoryPage/Breadcamp';
import Content from '../components/categoryPage/Content';
import ProductsCategories from '../components/categoryPage/ProductsCategories'
import BlurpsBot from '../components/categoryPage/BlurpsBot';
// import Reviews from '../components/categoryPage/Reviews';
import Faq from '../components/categoryPage/Faq';
import { Helmet } from 'react-helmet'
import MailerLite from '../components/MailerLite';
import AboutUsBox from '../components/productPage/AboutUsBox';
import MailerLiteYoni from '../components/MailerLiteYoni';
import VideoBox from '../components/VideoBox';
import ValueBox from '../components/ValueBox';

const StyledContainer = styled.div`
        max-width: 1280px;
        width: 86%;
        margin: 0 auto;
        transition: 1s;
`

const StyledTrustPilot = styled.div`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 40px;
  h2 {
        font-size: 3.5rem;
        padding-top: 50px;
        text-align: center;
        padding-bottom: 50px;
        @media screen and (max-width: 650px){
            font-size: 2.6rem;    
            font-weight: bold;
        }
    }
    .review-desktop {
        @media screen and (max-width: 650px){
            display: none;
        }
    }
    .review-mobile {
        display:none;
        @media screen and (max-width: 650px){
            display: block;
        }
    }
`

const CategoryPage = props => {
    const { category, language, subCategories } = props;
    const shown = category.slug === 'yoni-eggs' || category.slug === 'yoni-eggs-it'

    const findFirstSubCat = () => {
        if (subCategories.visibles.length > 0) {
            const slugSubCat = subCategories.visibles.find(slug => subCategories.data[slug].parent_slug === category.slug)
            return [subCategories.data[slugSubCat]]
        }
        return null
    }

    const findOtherSubCat = () => {
        if (subCategories.visibles.length > 0) {
            const slugSubCat = subCategories.visibles.find(slug => subCategories.data[slug].parent_slug === category.slug)
            const slugArr = subCategories.visibles.filter(slug => slug !== slugSubCat)
            return slugArr.map(slug => {
                if (subCategories.data[slug].parent_slug === category.slug) {
                    return subCategories.data[slug]
                }
                return null;
            }).filter(obj => obj)
        }
        return null
    }

    return (
        <>
            <Helmet>
                <title>{category.meta_title}</title>
                <meta name="description" content={category.meta_description} />
                <meta property="og:title" content={category.meta_title} />
                <meta property="og:description" content={category.meta_description} />
                {category.meta_image && <meta property="og:image" content={category.meta_image.guid} />}
            </Helmet>
            <Header language={language} category={category} />
            <BlurpsTop category={category} />
            <StyledContainer id={category.slug}>
                {shown && <MailerLiteYoni />}
                <Breadcamp category={category} />
                <ProductsCategories subCategories={findFirstSubCat()} />
                {/* <Reviews category={category} language={language} /> */}
                {
                    language === 'it' ?
                        <StyledTrustPilot>
                            <h2>Cosa dicono i nostri Clienti:</h2>
                            <iframe title='reviews' className='review-desktop' id='AV_widget_iframe' frameBorder="0" width="100%" height="100%" src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbhorizontal_index.html"></iframe>
                            <iframe title='reviews' className='review-mobile' id='AV_widget_iframe' frameBorder="0" width="100%" height="1000px" src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbindex.html"></iframe>
                        </StyledTrustPilot>
                        : <StyledTrustPilot>
                            <h2>What our Customers say:</h2>
                                <iframe title='reviews' className='review-desktop' id='AV_widget_iframe' frameBorder="0" width="100%" height="100%" src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbhorizontal_index.html"></iframe>
                                <iframe title='reviews' className='review-mobile' id='AV_widget_iframe' frameBorder="0" width="100%" height="1000px" src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbindex.html"></iframe>
                        </StyledTrustPilot>
                }
                <AboutUsBox />
                <ProductsCategories subCategories={findOtherSubCat()} />
                <ValueBox />
                <Content category={category} />
                <BlurpsBot category={category} />
                {category.video && <VideoBox video={category.video} title={category.video_title} />}
                <Faq category={category} showGeneral={true} />
            </StyledContainer>
            <MailerLite />
        </>
    )
}

function mapStateToProps(state) {
    return {
        subCategories: state.subCategories,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);