import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";

const StyledContent = styled.section`
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 100px;
    @media screen and (max-width: 650px) {
        padding-bottom: 20px;
    }
    h2 {
        font-size: 3.6rem;
        text-align: center;
        padding: 30px 0;
        @media screen and (max-width: 650px) {
            font-size: 2.6em;
            padding-bottom: 10px;
        }
    }
    .content-card {
        display: flex;
        justify-content: space-between;
        padding-top: 50px;
        @media screen and (max-width: 650px) {
            padding-top: 0;
        }
        @media screen and (max-width: 850px) {
            flex-direction: column;
        }
        img {
            width: 47.5%;
            height: fit-content;
            @media screen and (max-width: 850px) {
                width: 100%;
            }
            @media screen and (max-width: 650px) {
                padding-top: 10px;
            }
        }
        div {
            width: 47.5%;
            font-size: 2rem;
            line-height: 1.5;
            text-align: left;
            @media screen and (max-width: 1150px) {
                font-size: 1.6rem;
                line-height: 1.4;
            }
            @media screen and (max-width: 850px) {
                font-size: 2rem;
                line-height: 1.5;
                width: 100%;
                padding-top: 20px;
            }
            @media screen and (max-width: 650px) {
                font-size: 1.6em;
            }
        }
        a {
            color: blue;
        }
    }
`

const Content = props => {
    const { category, media, match } = props;
    if (media.data[category.content_img_1.ID] && media.data[category.content_img_2.ID]) {
        return (
            <StyledContent>
                <h2>{category.content_title}</h2>
                <div className='content-card top-content'>
                    <div dangerouslySetInnerHTML={{ __html: category.content_text_1 }} />
                    <img src={category.content_img_1.guid} alt={match.params.language === 'it' ? media.data[category.content_img_1.ID].alternative_text_it : media.data[category.content_img_1.ID].alt_text} />
                </div>
                <div className='content-card bot-content'>
                    <img src={category.content_img_2.guid} alt={match.params.language === 'it' ? media.data[category.content_img_2.ID].alternative_text_it : media.data[category.content_img_2.ID].alt_text} />
                    <div dangerouslySetInnerHTML={{ __html: category.content_text_2 }} />
                </div>
            </StyledContent>
        )
    }
    return null;
}

function mapStateToProps(state) {
    return {
        media: state.media,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));