import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";

const StyledAboutUs = styled.article`
    display: flex;
    background-color: #5cb597;
    border: 8px solid #5cb597;
    align-items: center;
    margin: 20px 0;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
    img{
        width: 400px;
        @media screen and (max-width: 1000px) {
            width: 100%;
        }
    }
    div{
        padding: 0 25px;
        @media screen and (max-width: 1000px) {
            padding: 25px;
        }
        h3{
            font-size: 3rem;
            color: black;
            @media screen and (max-width: 1000px) {
                font-size: 2rem;
            }
        }
        p{
            padding-top: 15px;
            color: white;
            font-size: 2rem;
            @media screen and (max-width: 1000px) {
                font-size: 1.4rem;
            }
        }
    }
`

const AboutUsBox = props => {
    const { media, general, match } = props;
    if (general.data[general.visibles]) {
        return (
            <StyledAboutUs>
                {media.data[general.data[general.visibles].about_us_box_image.ID] &&<img src={general.data[general.visibles].about_us_box_image.guid} alt={match.params.language === 'it' ? media.data[general.data[general.visibles].about_us_box_image.ID].alternative_text_it : media.data[general.data[general.visibles].about_us_box_image.ID].alt_text} />}
                <div>
                    <h3>{general.data[general.visibles].about_us_box_title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: general.data[general.visibles].about_us_box_description}}/>
                </div>
            </StyledAboutUs>
        )
    }
    return null;
}

function mapStateToProps(state) {
    return {
        media: state.media,
        general: state.general
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AboutUsBox));