import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uuid from 'uuid/v4'


import "react-responsive-carousel/lib/styles/carousel.min.css";

import ProductCard from '../ProductCard';

const StyledProductsCards = styled.div`
    text-align: center;
    padding-top: 50px;
    h2 {
        font-size: 3.6rem;
        @media screen and (max-width: 650px){
            font-size: 2.6rem;  
            font-weight: bold;  
        }
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        text-align: center;
    }
`



const ProductsCards = props => {
    const { products, title, tagSlug, language } = props;

    return (
        <StyledProductsCards >
            <h2>{title}</h2>
            <div className='container'>
                {products.slugList.map(slug => {
                    const product = products.data[slug];
                    return products.data[slug].tags.map(tS => {
                        if (tS === tagSlug) {
                            return <ProductCard product={product} key={uuid} language={language}/>
                        }
                        return null;
                    })
                })}
            </div>
        </StyledProductsCards>
    )
}

function mapStateToProps(state) {
    return {
        products: state.products,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsCards));