import { combineReducers } from 'redux';
import * as types from '../../actions/actionTypes'

const slugList = (state = [], action) => {
    switch (action.type) {
        case types.ADD_TAGS:
            return action.payload.result;
        default:
            return state;
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_TAGS:
            return action.payload.entities.tags;
        default:
            return state;
    }
}

const tags = combineReducers({
    slugList,
    data
})

export default tags;