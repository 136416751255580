import React from 'react';
import styled from 'styled-components';

const StyledShipping = styled.section`
    padding-top: 20px;
    max-width: 80%;
    margin: 0 auto;
    h3 {
        padding: 40px 0;
        font-size: 2.6rem;
    }
    p{
        font-size: 1.6rem;
        line-height: 1.5
    }
    .description{
        padding-bottom: 30px;
    }
    .card{
        border-top: 1px solid #80808054;
        padding: 30px 0;
        .info{
            display: flex;
            align-items: center;
            padding-bottom: 15px;
            img{
                height: 45px;
                margin-right: 20px;
            }
            p{
                font-weight: bolder;
                color: black;
            }
        }
        .button{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;
            font-size: 1.6rem;
            border: 1px solid black;
            padding: 8px 20px;
            width: 160px;
            p{
                color: black;
            }
            img{
                height: 8px;
            }
        }
    }
`

const AssistanceShippingPage = props => {
    const { match } = props;

    return(
        <StyledShipping>
            <h3>{match.params.language === 'it' ? 'Hai problemi con la spedizione o non sai dov\'è il tuo ordine?' : 'Do you have problems with your order?'}</h3>
            <p className='description'>{match.params.language === 'it' ? 'Siamo qua per risolvere qualsiasi problema tu abbia con il tuo ordine. Contattaci e ti risponderemo direttamente dal nostro centro di logistica ' : 'We are here to solve any problem you could have with your order. Contact us and we will reply directly from our logistics center'}</p>
            <div className='card'>
                <a className='info' href={match.params.language === 'it' ? ' https://blog.yogicyantra.com/blog/assistenza-logistica/' : 'https://blog.yogicyantra.com/blog/en/logistic/'}>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/06/Mail-logo-blu.png" alt="" />
                    <p>{match.params.language === 'it' ? 'Scrivici ' : 'Contact Us'}</p>
                </a>
                <p>{match.params.language === 'it' ? 'Potremmo avere bisogno di uno due giorni per risponderti' : 'We may need one or two days to reply to you'}</p>
                <a className='button' href={match.params.language === 'it' ? ' https://blog.yogicyantra.com/blog/assistenza-logistica/' : 'https://blog.yogicyantra.com/blog/en/logistic/'}>
                    <p>{match.params.language === 'it' ? 'Scrivici' : 'Contact Us'}</p>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/06/Arrow-black-orizontal.png" alt="" />
                </a>
            </div>
        </StyledShipping>
    )
}

export default AssistanceShippingPage;