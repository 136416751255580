import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uuid from 'uuid/v4'
import { Link } from 'react-router-dom';


import QAndA from '../components/categoryPage/QAndA'

import { findFaq } from '../redux/helpers'

const StyledAssistence = styled.section`
    padding-top: 20px;
    max-width: 80%;
    margin: 0 auto;
    h3 {
        padding: 30px 0;
        font-size: 3.4rem;
        text-align: center
    }
    p{
        font-size: 1.8rem;
        line-height: 1.5
    }
    .buttons{
        display: flex;    
        justify-content: space-around;
        margin: 30px;
        @media screen and (max-width: 1000px) {
            flex-direction: column;
            margin: 30px 0;
        }
        a{
            border: 1px solid black;
            border-radius: 8px;
            font-size: 1.8rem;
            padding: 15px;
            @media screen and (max-width: 1000px) {
                margin-top: 20px;
                text-align: center;
            }
        }
    }
`

const AssistencePage = props => {
    const { generalFaq, categories, match } = props;

    return (
        <StyledAssistence>
            <h3>{match.params.language === 'it' ? 'Namastè, come possiamo aiutarti?' : 'Namastè, how could we help you?'}</h3>
            <p>{match.params.language === 'it' ? 'Nella sezione FAQ puoi trovare più velocemente la risposta alle tue domande, compresi i metodi di pagamento che accettiamo, i tempi di spedizione e come prendersi cura dei nostri prodotti. Se non trovi risposta alla tua domanda, o hai bisogno di aiuto specifico, parla con noi premendo uno dei bottoni qui sotto.' : 'In the FAQ section you can find the answer to your questions faster, including the payment methods we accept, shipping times and how to take care of our products. If you can\'t find an answer to your question, or if you need specific help, talk to us by pressing one of the buttons below.'}</p>
            <div className='buttons'>
                <Link to={`/${match.params.language}/assistance_products`}>{match.params.language === 'it' ? 'Ho una domanda sui prodotti' : 'I have a question about a product'}</Link>
                <Link to={`/${match.params.language}/assistance_shipping`}>{match.params.language === 'it' ? 'Ho un problema con la spedizione' : 'I have a question about my order'}</Link>
            </div>
            <h3>Frequently Asked Questions</h3>
            {generalFaq.map(qAndA => (
                <QAndA key={uuid()} qAndA={qAndA} />
            ))}
            {categories.visibles && categories.visibles.map(slug =>
                findFaq(categories.data[slug]) && findFaq(categories.data[slug]).map(qAndA => (
                    <QAndA key={uuid()} qAndA={qAndA} />
                )))}
        </StyledAssistence>
    )
}

function mapStateToProps(state) {
    return {
        generalFaq: state.general.generalFaq,
        categories: state.categories
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AssistencePage);