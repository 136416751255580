import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components'
import uuid from 'uuid/v4'

import { changeAddProdModal } from '../../redux/actions/actionCreators'

import SingleCategory from './SingleCategory'
import MobileMenu from './MobileMenu';
import TrustBox from '../TrustBox'

const StyledHeader = styled.section`
    height: 110px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    font-size: 1.6rem;
    @media screen and (max-width: 1250px) {
        font-size: 1.3rem;
    }
    @media screen and (max-width: 1100px) {
        position: sticky;
        width: 100%;
        background-color: white;
        z-index: 2;
        top: 0;
        font-size: 1.6rem;
    }
    @media screen and (max-width: 650px) {
        height: 59px;
    }
    .container {
        display: flex;
        justify-content: space-around;
        .logo{
            max-width: 300px;
            padding-top: 13px;
            @media screen and (max-width: 650px) {
                width: 58%;
                padding-top: 5px;
            } 
        }
    }
    .hamburger {
        display: none;
        height: 50px;
        margin-top: 35px;
        @media screen and (max-width: 1100px) {
            display: block;
            height: 30px;
        } 
        @media screen and (max-width: 650px) {
            height: 26px;
            margin-top: 14px;
        }
    }
    .desktop-menu {
        display: flex;
        align-items: center;
        padding-top: 46px;
        @media screen and (max-width: 1100px) {
            display: none;
        } 
        li {
            padding: 0 5px;
        }
    }
    div ul li div {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-right: 10px;
    } 
`

const StyledTrustPilot = styled.div`
    border-bottom: 1px solid black;
    padding: 3px 0;
    @media screen and (max-width: 1100px) {
        /* display: none; */
    }
`

const Header = props => {
    const { categories, header, language, addProdModal, changeAddProdModal } = props;
    const [isShown, setIsShown] = useState(false)
    const toogleMenu = () => {
        setIsShown(!isShown);
    }

    const closeAddProdModal = () => {
        if (addProdModal) {
            changeAddProdModal(false)
        }
        return;
    }

    return (
        <>
            <StyledHeader onClick={closeAddProdModal}>
                <div className='container'>
                    {language === 'it' || language === 'en' ?
                        <NavLink to={`/${language}`}><img className='logo' src={Object.keys(header).length && header.logo.guid} alt="Logo" /></NavLink> :
                        <NavLink to={`/en`}><img className='logo' src={Object.keys(header).length && header.logo.guid} alt="Logo" /></NavLink>
                    }
                    <div className='right-container'>
                        <img onClick={toogleMenu} className='hamburger' src="https://payments.yogicyantra.com/wp-content/uploads/2020/03/hamburger.png" alt="hamburger" />
                        <MobileMenu language={language} isShown={isShown} toogleMenu={toogleMenu} />
                        <ul className='desktop-menu'>
                            {language === 'it' || language === 'en' ?
                                <li><NavLink to={`/${language}/shop_now`} activeStyle={{ textDecoration: 'underline' }} >{language === 'it' ? 'TUTTI I PRODOTTI' : 'ALL PRODUCTS'}</NavLink></li> :
                                null
                            }
                            {categories.visibles.map(slug =>
                                <SingleCategory key={uuid()} language={language} category={categories.data[slug]} screen='desktop' />
                            )}
                            <li><a href="https://blog.yogicyantra.com/" target="_blank" rel="noopener noreferrer">Blog</a></li>
                        </ul>
                    </div>
                </div>
            </StyledHeader>
            {language === 'it' ?
                <StyledTrustPilot>
                    <TrustBox attributes={{
                        locale: "it-IT", template: "5419b6a8b0d04a076446a9ad", height: "24px", width: "100%", theme: "light", tags: "eng", stars: "4,5"
                    }} />
                </StyledTrustPilot>
                :
                <StyledTrustPilot>
                    <TrustBox attributes={{
                        locale: "en-GB", template: "5419b6a8b0d04a076446a9ad", height: "24px", width: "100%", theme: "light", tags: "eng", stars: "4,5"
                    }} />
                </StyledTrustPilot>
            }
        </>
    )
}


function mapStateToProps(state) {
    return {
        addProdModal: state.addProdModal,
        categories: state.categories,
        header: state.general.header
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeAddProdModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
