import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uuid from 'uuid/v4';


const StyledSelect = styled.div`
    .select-outer{
        @media screen and (max-width: 1000px) {
        order: -2;
        }
    }
    .select {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 20px;
        h4{
            font-size: 1.6rem;
            font-weight: 700;
            padding-top: 5px;
            @media screen and (max-width: 650px) {
                font-size: 1.4rem;
            }
        }
        select {
            height: 30px;
            font-size: 1.4rem;
            background-color: #ececec;
            border: 0;
            width: 180px;
            margin-bottom: 10px;
            @media screen and (max-width: 650px) {
                font-size: 1.3rem;
                height: 23px;
            }
        }
    }
`

const Select = props => {
    const { product, attributeSelected, setAttributeSelected, match} = props;

    return (
        <StyledSelect>
            <div className='select-outer'>
                {product.attributes.map(attribute => {
                    if (match.params.language === 'it' && attribute.name.endsWith('_it')) {
                        return (
                            <div className='select' key={uuid()}>
                                <h4>{attribute.name.slice(0, -3)}</h4>
                                <select onChange={e => {
                                    setAttributeSelected(e.target.value)
                                }} value={attributeSelected} name="" id="">
                                    {attribute.options.map(option => <option key={uuid()}>{option}</option>)}
                                </select>
                            </div>
                        )
                    }
                    if (match.params.language === 'en' && !attribute.name.endsWith('_it')) {
                        return (
                            <div className='select' key={uuid()}>
                                <h4>{attribute.name}</h4>
                                <select onChange={e => {
                                    setAttributeSelected(e.target.value)
                                }} value={attributeSelected} name="" id="">
                                    {attribute.options.map(option => <option key={uuid()}>{option}</option>)}
                                </select>
                            </div>
                        )
                    }
                    return null
                })}
            </div>
        </StyledSelect>
    )
}

function mapStateToProps(state) {
    return {
    
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Select)) 