import React from 'react';
import { Redirect } from 'react-router-dom';

const LanguageRedirection = props => {
    return (
        <>
            {window.navigator.language !== 'it-IT' ?
                <Redirect to='/en' /> :
                <Redirect to='/it' />
            }
        </>
    )
}

export default LanguageRedirection;