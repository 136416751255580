import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet'

import TrustedBy from '../components/homePage/TrustedBy'
import AboutUsBox from '../components/productPage/AboutUsBox';
import TrustBox from '../components/TrustBox';
import Faq from '../components/categoryPage/Faq'
import VideoBox from '../components/VideoBox';

const StyledReferral = styled.section`
    .header{
        position: relative;
        background-image: ${props => `url(${props.header})`};
        height: 700px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        .box{
            position: absolute;
            height: 500px;
            width: 550px;
            left: 100px;
            top: 100px;
            background-color: #ffffffcc;
            border-radius: 8px;
            padding: 70px 35px;
            @media screen and (max-width: 800px) {
                position: relative;
                left: 0;
                width: unset;
                margin: 0 40px;
                height: 500px;
            }
            @media screen and (max-width: 600px) {
                padding: 50px 25px;
            }
            h2{
                font-size: 2.8rem;
                font-weight: bold;
                color: black;
                padding-bottom: 30px;
                @media screen and (max-width: 800px) {
                    font-size: 2.5rem;
                }
            }
            p{
                font-size: 1.8rem;
                line-height: 1.5;
                @media screen and (max-width: 800px) {
                    font-size: 1.6rem;
                }  
            }
            a{
                display: block;
                position: absolute;
                bottom: 40px;
                text-align: center;
                color: black;
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                font-size: 2.1rem;
                font-weight: bold;
                padding: 15px 0;
                left: 75px;
                width: 400px;
                box-shadow: rgb(0 0 0 / 30%) 6px 6px 18px 0px;
                transition: 0.5s;
                :hover{
                    background-color: black;
                    color: white;
                }
                @media screen and (max-width: 800px) {
                    font-size: 1.8rem;
                    position: relative;
                    left: 0;
                    width: unset;
                    margin: 175px 40px;
                }
                @media screen and (max-width: 450px) {
                    margin: 100px 40px;
                }
                @media screen and (max-width: 350px) {
                    margin: 50px 40px;
                }
            }
        }
    }
    .container{
        max-width: 1280px;
        width: 86%;
        margin: 0 auto;
        h3{
            font-size: 2.2rem ;
            font-weight: bold;
            padding-bottom: 15px;
        }
        p{
            font-size: 1.8rem;
            line-height: 1.5;
        }
        .apply{
                display: block;
                text-align: center;
                color: black;
                background-color: white;
                border: 1px solid black;
                border-radius: 8px;
                font-size: 2.5rem;
                font-weight: bold;
                padding: 15px 0;
                box-shadow: rgb(0 0 0 / 30%) 6px 6px 18px 0px;
                transition: 0.5s;
                width: 500px;
                margin: 20px auto;
                :hover{
                    background-color: black;
                    color: white;
                }
                @media screen and (max-width: 800px) {
                    font-size: 2.2rem;
                    width: unset;
                }
            }

        .blurps{
            display: flex;
            justify-content: space-around;
            padding: 40px 0;
            border-bottom: 1px solid rgb(226, 226, 226);
            .blurp{
                img{
                    height: 75px;
                    padding-bottom: 20px;
                    padding-left: 19px;
                    @media screen and (max-width: 800px) {
                        height: 70px;
                        padding-left: 15px;
                    }
                }
                h4{
                    font-size: 2.1rem;
                    color: black;
                    font-weight: bold;
                    @media screen and (max-width: 800px) {
                        font-size: 1.8rem;
                    }
                }
            }
        }

        .info {
            border-bottom: 1px solid rgb(226, 226, 226);
            .card{
                display: flex;
                align-items: center;
                padding: 60px 0;
                @media screen and (max-width: 800px) {
                    flex-direction: column;
                    padding: 30px 0;
                }
                h3 {
                    @media screen and (max-width: 800px) {
                        text-align: center;
                    }
                }
                img {
                    height: 90px;
                    padding-right: 60px;
                    @media screen and (max-width: 800px) {
                        padding-bottom: 30px;
                        padding-right: 0;
                    }
                }
            }
        }

        .requisite{
            padding: 60px 0;
            border-bottom: 1px solid rgb(226, 226, 226);
            ul{
                padding: 10px 0;
            }
            li{
                list-style: circle inside;
                padding: 10px 0;
            }
            
        }

        .content{
            h2{
                text-align: center;
                font-size: 3.6rem;
                padding: 40px 0;
            }
            .card{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 25px 0;
                background-color: aliceblue;
                border: 3px solid black;
                @media screen and (max-width: 1000px) {
                    flex-direction: column;
                }
                img{
                    max-width: 340px;;
                    @media screen and (max-width: 1000px) {
                        max-width: 100%;
                    }
                }
                div{
                    padding: 0 30px;
                    @media screen and (max-width: 1000px) {
                        padding: 30px;
                    }
                }
            }
        }

        .how-it-work{
            padding: 40px 0;
            border-bottom: 1px solid rgb(226, 226, 226);
            p{
                padding: 30px 0;
            }
        }

        .contact-us{
            padding: 30px 0;
            text-align: center;
            font-size: 1.8rem;
            a{
                color: blue;
                text-decoration: underline;
            }
        }
    }
`

const StyledTrustPilot = styled.div`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 40px;
  h2 {
        font-size: 3.5rem;
        padding-top: 50px;
        text-align: center;
        padding-bottom: 50px;
        @media screen and (max-width: 650px){
            font-size: 2.6rem;    
            font-weight: bold;
        }
    }
`

const InfluencerReferralPage = props => {
    const { referralLP, media, match } = props;

    if (referralLP.visibles.length > 0) {

        return (
            <StyledReferral header={media.data[referralLP.data[referralLP.visibles[0]].main_img.ID].guid.rendered}>
                <Helmet>
                    <title>{referralLP.data[referralLP.visibles[0]].meta_title}</title>
                    <meta name="description" content={referralLP.data[referralLP.visibles[0]].meta_description} />
                </Helmet>
                <div className='header'>
                    <div className='box'>
                        <h2>{referralLP.data[referralLP.visibles[0]].description}</h2>
                        <p dangerouslySetInnerHTML={{ __html: referralLP.data[referralLP.visibles[0]].sub_description }} />
                        <a href="https://forms.gle/RVtKuLyvzkzu7KLo8">{referralLP.data[referralLP.visibles[0]].cta}</a>
                    </div>
                </div>

                <div className='container'>

                    <div className='blurps'>
                        <div className='blurp'>
                            <img src={media.data[referralLP.data[referralLP.visibles[0]].blurp_img_1.ID].guid.rendered} alt={match.params.language === 'it' ? media.data[referralLP.data[referralLP.visibles[0]].blurp_img_1.ID].alternative_text_it : media.data[referralLP.data[referralLP.visibles[0]].blurp_img_1.ID].alt_text} />
                            <h4>{referralLP.data[referralLP.visibles[0]].blurp_text_1}</h4>
                        </div>
                        <div className='blurp'>
                            <img src={media.data[referralLP.data[referralLP.visibles[0]].blurp_img_2.ID].guid.rendered} alt={match.params.language === 'it' ? media.data[referralLP.data[referralLP.visibles[0]].blurp_img_2.ID].alternative_text_it : media.data[referralLP.data[referralLP.visibles[0]].blurp_img_2.ID].alt_text} />
                            <h4>{referralLP.data[referralLP.visibles[0]].blurp_text_2}</h4>
                        </div>
                        <div className='blurp'>
                            <img src={media.data[referralLP.data[referralLP.visibles[0]].blurp_img_3.ID].guid.rendered} alt={match.params.language === 'it' ? media.data[referralLP.data[referralLP.visibles[0]].blurp_img_3.ID].alternative_text_it : media.data[referralLP.data[referralLP.visibles[0]].blurp_img_3.ID].alt_text} />
                            <h4>{referralLP.data[referralLP.visibles[0]].blurp_text_3}</h4>
                        </div>
                    </div>

                    <div className='info'>
                        <div className='card'>
                            <img src={media.data[referralLP.data[referralLP.visibles[0]].value_proposition_img_1.ID].guid.rendered} alt={match.params.language === 'it' ? media.data[referralLP.data[referralLP.visibles[0]].value_proposition_img_1.ID].alternative_text_it : media.data[referralLP.data[referralLP.visibles[0]].value_proposition_img_1.ID].alt_text} />
                            <div>
                                <h3>{referralLP.data[referralLP.visibles[0]].value_proposition_title_1}</h3>
                                <p>{referralLP.data[referralLP.visibles[0]].value_proposition_text_1}</p>
                            </div>
                        </div>
                        <div className='card'>
                            <img src={media.data[referralLP.data[referralLP.visibles[0]].value_proposition_img_2.ID].guid.rendered} alt={match.params.language === 'it' ? media.data[referralLP.data[referralLP.visibles[0]].value_proposition_img_2.ID].alternative_text_it : media.data[referralLP.data[referralLP.visibles[0]].value_proposition_img_2.ID].alt_text} />
                            <div>
                                <h3>{referralLP.data[referralLP.visibles[0]].value_proposition_title_2}</h3>
                                <p>{referralLP.data[referralLP.visibles[0]].value_proposition_text_2}</p>
                            </div>
                        </div>
                        <div className='card'>
                            <img src={media.data[referralLP.data[referralLP.visibles[0]].value_proposition_img_3.ID].guid.rendered} alt={match.params.language === 'it' ? media.data[referralLP.data[referralLP.visibles[0]].value_proposition_img_3.ID].alternative_text_it : media.data[referralLP.data[referralLP.visibles[0]].value_proposition_img_3.ID].alt_text} />
                            <div>
                                <h3>{referralLP.data[referralLP.visibles[0]].value_proposition_title_3}</h3>
                                <p>{referralLP.data[referralLP.visibles[0]].value_proposition_text_3}</p>
                            </div>
                        </div>
                    </div>

                    <div className='requisite'>
                        <h3>{referralLP.data[referralLP.visibles[0]].requisite_title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: referralLP.data[referralLP.visibles[0]].requisite_description }} />
                        <a className='apply' href="https://forms.gle/RVtKuLyvzkzu7KLo8">{referralLP.data[referralLP.visibles[0]].cta}</a>
                    </div>
                    <AboutUsBox />

                    <TrustedBy />

                    <div className='content'>
                        <h2>{referralLP.data[referralLP.visibles[0]].product_title_main}</h2>
                        <div className='card'>
                            <img src={media.data[referralLP.data[referralLP.visibles[0]].product_img_1.ID].guid.rendered} alt={match.params.language === 'it' ? media.data[referralLP.data[referralLP.visibles[0]].product_img_1.ID].alternative_text_it : media.data[referralLP.data[referralLP.visibles[0]].product_img_1.ID].alt_text} />
                            <div>
                                <h3>{referralLP.data[referralLP.visibles[0]].product_title_1}</h3>
                                <p dangerouslySetInnerHTML={{ __html: referralLP.data[referralLP.visibles[0]].product_text_1 }} />
                            </div>
                        </div>
                        <div className='card'>
                            <div>
                                <h3>{referralLP.data[referralLP.visibles[0]].product_title_2}</h3>
                                <p dangerouslySetInnerHTML={{ __html: referralLP.data[referralLP.visibles[0]].product_text_2 }} />
                            </div>
                            <img src={media.data[referralLP.data[referralLP.visibles[0]].product_img_2.ID].guid.rendered} alt={match.params.language === 'it' ? media.data[referralLP.data[referralLP.visibles[0]].product_img_2.ID].alternative_text_it : media.data[referralLP.data[referralLP.visibles[0]].product_img_2.ID].alt_text} />
                        </div>
                        <div className='card'>
                            <img src={media.data[referralLP.data[referralLP.visibles[0]].product_img_3.ID].guid.rendered} alt={match.params.language === 'it' ? media.data[referralLP.data[referralLP.visibles[0]].product_img_3.ID].alternative_text_it : media.data[referralLP.data[referralLP.visibles[0]].product_img_3.ID].alt_text} />
                            <div>
                                <h3>{referralLP.data[referralLP.visibles[0]].product_title_3}</h3>
                                <p dangerouslySetInnerHTML={{ __html: referralLP.data[referralLP.visibles[0]].product_text_3 }} />
                            </div>
                        </div>
                    </div>

                    <VideoBox video={referralLP.data[referralLP.visibles[0]].video} title={referralLP.data[referralLP.visibles[0]].video_title} />

                    {
                        match.params.language === 'it' ?
                            <StyledTrustPilot>
                                <h2>Centinaia di clienti soddisfatti</h2>
                                <TrustBox attributes={{
                                    locale: "it-IT", template: "539adbd6dec7e10e686debee", height: "500px", width: "100%", theme: "light", tags: "Generale", stars: "4,5", language: 'it'
                                }} />
                            </StyledTrustPilot>
                            : <StyledTrustPilot>
                                <h2>Centinaia di clienti soddisfatti</h2>
                                <TrustBox attributes={{
                                    locale: "en-GB", template: "539adbd6dec7e10e686debee", height: "500px", width: "100%", theme: "light", tags: "eng", stars: "4,5", language: 'en'
                                }} />
                            </StyledTrustPilot>
                    }


                    <div className='how-it-work'>
                        <h3>{referralLP.data[referralLP.visibles[0]].how_it_work_title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: referralLP.data[referralLP.visibles[0]].how_it_work_description }} />
                        <a className='apply' href="https://forms.gle/RVtKuLyvzkzu7KLo8">{referralLP.data[referralLP.visibles[0]].cta}</a>
                    </div>

                    <Faq category={referralLP.data[referralLP.visibles[0]]} />

                    <p className='contact-us'>{match.params.language === 'it' ? 'Hai dubbi?' : 'Need info?'} <a href={match.params.language === 'it' ? 'https://blog.yogicyantra.com/blog/contatti/' : 'https://blog.yogicyantra.com/blog/en/contact/'}>{match.params.language === 'it' ? 'Contattaci' : 'Contact us'}</a></p>
                </div>
            </StyledReferral>
        )
    } return null;
}

function mapStateToProps(state) {
    return {
        referralLP: state.referralLP,
        media: state.media
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerReferralPage);