import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'
import uuid from 'uuid/v4';

import Title from './Title';
import ProductCard from './ProductCard';
import TotalsTab from './TotalsTab';
import MofoPhrase from './MofoPhrase';

import { getProductsNumber } from '../../helpers/cartHelpers'
import { addSelectedShipping } from '../../redux/actions/actionCreators'
import RelatedProducts from './RelatedProducts';

const StyledCartMain = styled.div`
    .container {
        max-width: 1200px;
        margin: 40px auto;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1000px) {
            flex-direction: column;
            align-items: center;
        }
        .left-column {
            width: 60%;
            @media screen and (max-width: 1000px) {
                width: 90%;
                margin-bottom: 30px;
            }
        }
        .right-column {
            @media screen and (max-width: 1000px) {
                width: 90%;
            }
        }
    }
`

const CartMain = props => {
    const { match, products, cart, shippingMethods, country, addSelectedShipping } = props;

    useEffect(() => {
        if (shippingMethods.shippingAll.length > 0 && Object.values(cart).length > 0) {
            switch (country) {
                case 'IT':
                    addSelectedShipping(shippingMethods.shippingIt)
                    break;
                case 'BE': case 'BG': case 'CZ': case 'DK': case 'DE': case 'EE': case 'IE': case 'EL': case 'ES': case 'FR': case 'HR': case 'CY': case 'LV':
                case 'LT': case 'LU': case 'HU': case 'MT': case 'NL': case 'AT': case 'PL': case 'PT': case 'RO': case 'SI': case 'SK': case 'FI': case 'SE':
                case 'UK': case 'IS': case 'NO': case 'LI': case 'CH': case 'ME': case 'MK': case 'AL': case 'RS': case 'TR': case 'BA':
                    addSelectedShipping(shippingMethods.shippingEu)
                    break;
                case 'US': case 'CA':
                    addSelectedShipping(shippingMethods.shippingUs)
                    break;
                default:
                    addSelectedShipping(shippingMethods.shippingAll)
            }
        }
        // eslint-disable-next-line
    }, [shippingMethods.shippingAll])



    return (
        <StyledCartMain>
            <div className='container'>
                <div className='left-column'>
                    <Title language={match.params.language} productsNumber={getProductsNumber(cart)} />
                    {products.slugList.length && Object.entries(cart).map(([key, product]) => (
                        <ProductCard key={uuid()} product={product} cartKey={key} />
                    ))}
                    <MofoPhrase language={match.params.language} />
                </div>
                <div className='right-column'>
                    <TotalsTab />
                </div>
            </div>
            <RelatedProducts />
        </StyledCartMain>
    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        products: state.products,
        country: state.country,
        shippingMethods: state.shippingMethods,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addSelectedShipping
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartMain));