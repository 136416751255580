export const FETCHING_API = 'FETCHING_API';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const ADD_CATEGORIES = 'ADD_CATEGORIES';
export const SET_VISIBLE_CATEGORIES = 'GET_VISIBLE_CATEGORIES';
export const ADD_SUB_CATEGORIES = 'ADD_SUB_CATEGORIES';
export const SET_VISIBLE_SUB_CATEGORIES = 'GET_VISIBLE_SUB_CATEGORIES';
export const ADD_HOME_PAGE = 'ADD_HOME_PAGE';
export const ADD_SHOP_NOW = 'ADD_SHOP_NOW';
export const ADD_GENERAL_FAQ = 'ADD_GENERAL_FAQ';
export const ADD_HEADER = 'ADD_HEADER';
export const ADD_PRODUCT_PAGE = 'ADD_PRODUCT_PAGE';
export const ADD_GENERAL = 'ADD_GENERAL';
export const SET_VISIBLE_GENERAL = 'SET_VISIBLE_GENERAL';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const ADD_CART = 'ADD_CART';
export const ADD_LOCATION = 'ADD_LOCATION';
export const ADD_SHIPPING_METHODS = 'ADD_SHIPPING_METHODS';
export const ADD_SELECTED_SHIPPING = 'ADD_SELECTED_SHIPPING';
export const ADD_MEDIA = 'ADD_MEDIA';
export const ADD_VARIATONS = 'ADD_VARIATONS';
export const ADD_SELECTED_VARIATION = 'ADD_SELECTED_VARIATION';
export const VARIATONS_FAILURE = 'VARIATONS_FAILURE';
export const ADD_COUPONS = 'ADD_COUPONS';
export const SET_APPLIED_COUPON = 'SET_APPLIED_COUPON';
export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export const ADD_TAGS = 'ADD_TAGS';
export const ADD_REFERRAL_LP = 'ADD_REFERRAL_LP';
export const SET_VISIBLE_REFERRAL_LP = 'SET_VISIBLE_REFERRAL_LP';
export const ADD_LEAFLET = 'ADD_LEAFLET';
export const SET_VISIBLE_LEAFLET = 'SET_VISIBLE_LEAFLET';
export const CHANGE_ADD_PROD_MODAL = 'CHANGE_ADD_PROD_MODAL';