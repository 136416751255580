import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { changeAddProdModal } from '../../redux/actions/actionCreators'

const StyledAddedProd = styled.div`
    height: ${props => props.isShown ? '70px' : '0'};
    visibility: ${props => props.isShown ? 'visible' : 'hidden'};
    opacity: ${props => props.isShown ? '1' : '0'};
    transition: height 1s, visibility 1s, opacity 0.5s;
    position: fixed;
    bottom: 0;
    display: flex;
    background: white;
    border-top: 2px solid black;
    left: 0;
    width: 100%;
    justify-content: space-around;
    z-index: 5;
    @media screen and (max-width: 885px) {
        height: ${props => props.isShown ? '105px' : '0'};
        padding-top: 20px;
    }
    .continue-button {
        cursor: pointer;
        font-size: 1.4rem;
        background-color: white;
        border: 1px solid black;
        border-radius: 8px;
        box-shadow: rgb(136 136 136 / 67%) 2px 2px 1px 0px;
        color: rgb(12, 12, 12);
        height: 27px;
        margin-top: 16px;
        padding: 6px 2%;
        @media screen and (max-width: 400px) {
            margin-left: 3px;
            height: 37px;
            padding: 10px 2%;
        }   
    }
    h3 {
        text-align: center;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 15px 0;
        color: black;
        @media screen and (max-width: 400px) {
            padding: 15px 8px 5px 8px;
        }   
    }
    p {
        font-size: 1.4rem;        
        padding-bottom: 15px;
        text-align: center;
        color: black;
        @media screen and (max-width: 400px) {
            padding: 0 8px 15px 8px;
        }   
    }
    .cart-button{
        height: 27px;
        margin-top: 16px;
        background: linear-gradient(#00af6b,#00af6be0);
        border-color: rgba(10,10,10,0);
        border-radius: 8px;
        box-shadow:rgb(136, 136, 136) 2px 2px 1px 0px;
        padding: 7px 2%;
        :hover{
            background: linear-gradient(#008a54, #008a54d9);
        }
        @media screen and (max-width: 400px) {
            height: 37px;
            padding: 7px 2%;
            margin-right: 5px;   
        }   
        a {
            color: white;
            display: block ruby;
            white-space: nowrap;
            font-size: 1.4rem;
            text-align: center;
            @media screen and (max-width: 400px) {
                padding: 5px 2%;   
            }   
        }
    }
`

const AddedProdModal = props => {
    const { product, changeAddProdModal, language, isShown } = props;
    return (
        <StyledAddedProd isShown={isShown}>
            <div className='continue-button' onClick={() => changeAddProdModal(false)}>{language === 'it' ? 'Continua' : 'Continue'}</div>
            <div>
                <h3>{language === 'it' ? 'HAI AGGIUNTO IL SEGUENTE PRODOTTO' : 'YOU HAVE ADDED THE FOLLOWING PRODUCT'}</h3>
                {product && <p>{product.name.toUpperCase()} - {product.price} €</p>}
            </div>
            <div className='cart-button'>
                <Link to={`/${language}/cart`}>{language === 'it' ? 'Vai al Carrello' : 'Go to Cart'}</Link>
            </div>
        </StyledAddedProd>
    )
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeAddProdModal
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddedProdModal);