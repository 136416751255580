import { combineReducers } from 'redux';

import * as types from '../../actions/actionTypes'

const slugList = (state = [], action) => {
    switch (action.type) {
        case types.ADD_GENERAL:
            return action.payload.result;
        default:
            return state;
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_GENERAL:
            return action.payload.entities.general;
        default:
            return state;
    }
}

const visibles = (state = [], action) => {
    switch (action.type) {
        case types.SET_VISIBLE_GENERAL:
            return action.payload
        default:
            return state;
    }
}

export const header = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_HEADER:
            return action.payload;
        default:
            return state;
    }
}

export const homePage = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_HOME_PAGE:
            return action.payload;
        default:
            return state;
    }
}

export const shopNow = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_SHOP_NOW:
            return action.payload;
        default:
            return state;
    }
}

export const generalFaq = (state = [], action) => {
    switch (action.type) {
        case types.ADD_GENERAL_FAQ:
            return action.payload;
        default:
            return state;
    }
}

export const productPage = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_PRODUCT_PAGE:
            return action.payload;
        default:
            return state;
    }
}

const general = combineReducers({
    slugList,
    data,
    visibles,
    header,
    homePage,
    shopNow,
    generalFaq,
    productPage
})

export default general;