import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { getCorrectMetaData } from '../../helpers/getCorrectMetaData'


const StyledTabs = styled.div`
    text-align: left;
    margin-top: 40px;
    h3 {
        font-size: 1.6rem;
        padding: 15px 4%;
        border-top: 1px solid #d9d9d9;
        cursor: pointer;
        :last-of-type {
            border-bottom: 1px solid #d9d9d9;
        }
    }
    .description-title {
        background-color: ${props => props.isActive === 'description-title' ? '#c1c1c130' : 'inherit'}
    }
    .custom-title {
        background-color: ${props => props.isActive === 'custom-title' ? '#c1c1c130' : 'inherit'}
    }
    .features-title {
        background-color: ${props => props.isActive === 'features-title' ? '#c1c1c130' : 'inherit'}
    }
    .tab-text {
        font-size: 1.6rem;
        line-height: 2;
    }
    .description {
        height: ${props => props.selected === 'description' ? '100%' : '0'};
        opacity: ${props => props.selected === 'description' ? '100%' : '0%'};
        padding: ${props => props.selected === 'description' ? '40px 4%' : '0 4%'};
        visibility: ${props => props.selected === 'description' ? 'visible' : 'hidden'};
        transition: opacity .5s, heigth 0s, padding .5s, visibility .5s; 
        z-index: ${props => props.selected === 'description' ? '10' : '-10'};
    }
    .custom {
        height: ${props => props.selected === 'custom' ? '100%' : '0'};
        opacity: ${props => props.selected === 'custom' ? '100%' : '0%'};
        padding: ${props => props.selected === 'custom' ? '40px 4%' : '0 4%'};
        visibility: ${props => props.selected === 'custom' ? 'visible' : 'hidden'};
        transition: opacity .5s, heigth 0s, padding .5s, visibility .5s; 
        z-index: ${props => props.selected === 'description' ? '10' : '-10'};
    }
    .features {
        height: ${props => props.selected === 'features' ? '100%' : '0'};
        opacity: ${props => props.selected === 'features' ? '100%' : '0%'};
        padding: ${props => props.selected === 'features' ? '40px 4%' : '0 4%'};
        visibility: ${props => props.selected === 'features' ? 'visible' : 'hidden'};
        z-index: ${props => props.selected === 'description' ? '10' : '-10'};
        transition: opacity .5s, heigth 0s, padding .5s, visibility .5s; 
    }
`

const Tabs = props => {
    const { subCategories, subCategory, products, product, match } = props;

    const [isActive, setIsActive] = useState('description-title')
    const [tabTextOpen, setTabTextOpen] = useState('description')

    const openActive = activeClass => {
        setTabTextOpen(activeClass)
        setIsActive(`${activeClass}-title`)
    }

    return (
        <StyledTabs isActive={isActive} selected={tabTextOpen}>
            <h3 className='description-title' onClick={() => openActive('description')}>{match.params.language === 'it' ? 'Descrizione' : 'Description'}</h3>
            <h3 className='custom-title' onClick={() => openActive('custom')}>{subCategories.data[subCategory].product_page_tab_title}</h3>
            <h3 className='features-title' onClick={() => openActive('features')}>{match.params.language === 'it' ? 'Caratteristiche e Misure' : 'Features and Measures'}</h3>
            <div className='tab-text description' dangerouslySetInnerHTML={{ __html: match.params.language === 'it' ? getCorrectMetaData(product, products, 'long_description_it') : getCorrectMetaData(product, products, 'long_description') }} />
            <div className='tab-text custom' dangerouslySetInnerHTML={{ __html: match.params.language === 'it' ? getCorrectMetaData(product, products, 'custom_tab_text_it') : getCorrectMetaData(product, products, 'custom_tab_text') }} />
            <div className='tab-text features' dangerouslySetInnerHTML={{ __html: subCategories.data[subCategory].product_page_features_text }} />
        </StyledTabs>
    )
}

function mapStateToProps(state) {
    return {
        products: state.products,
        subCategories: state.subCategories,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
           
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tabs)) 