import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uuid from 'uuid/v4'

import QAndA from '../components/categoryPage/QAndA'

import { findFaq } from '../redux/helpers'

const StyledFaq = styled.section`
    padding-top: 20px;
    max-width: 80%;
    margin: 0 auto;
    h3 {
        padding: 30px 0;
        font-size: 3.4rem;
        text-align: center
    }
`


const FaqPage = props => {
    const { generalFaq, categories } = props;

    return (
        <StyledFaq>
            <h3>Frequently Asked Questions</h3>
            {generalFaq.map(qAndA => (
                <QAndA key={uuid()} qAndA={qAndA} />
            ))}
            {categories.visibles && categories.visibles.map(slug =>
                findFaq(categories.data[slug]) && findFaq(categories.data[slug]).map(qAndA => (
                    <QAndA key={uuid()} qAndA={qAndA} />
                )))}
        </StyledFaq>
    )
}

function mapStateToProps(state) {
    return {
        generalFaq: state.general.generalFaq,
        categories: state.categories
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqPage);