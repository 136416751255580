const findId = (category, products, variations, product) => {
  let result = ''
  const productsIds = products.slugList.map(slug => {
      if (products.data[slug].categories.some(obj => obj.slug === category)) {
          return products.data[slug].id
      }
      return null;
  })
  let resultArr = productsIds.filter(item => item)
  
  resultArr.forEach(id => {
      result += `${id},`
  })

  return result.slice(0, -1);
}

export default findId;