import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uuid from 'uuid/v4'
import { Helmet } from 'react-helmet'

import Breadcamp from '../components/categoryPage/Breadcamp'
import TopContent from '../components/shopNow/TopContent'
import ProductsCategories from '../components/categoryPage/ProductsCategories'
import MailerLite from '../components/MailerLite';
import AboutUsBox from '../components/productPage/AboutUsBox';
import ValueBox from '../components/ValueBox';

const StyledContainer = styled.div`
        max-width: 1280px;
        width: 86%;
        margin: 0 auto;
`

const ShopNow = props => {
    const { shopNow, categories, history, match, subCategories, homePage } = props;
    const findSubCategories = () => {
        return categories.visibles.map(categorySlug => {
            return subCategories.visibles.map(subCatSlug => {
                if (subCategories.data[subCatSlug].parent_slug === categories.data[categorySlug].slug) {
                    return subCategories.data[subCatSlug]
                }
                return null;
            }).filter(obj => obj)
        }).flat()
    }
    return (
        <div>
            <StyledContainer>
                <Helmet>
                    <title>{shopNow.meta_title}</title>
                    <meta name="description" content={shopNow.meta_description} />
                    <meta property="og:title" content={shopNow.meta_title} />
                    <meta property="og:description" content={shopNow.meta_description} />
                    {homePage.meta_image && <meta property="og:image" content={homePage.meta_image.guid} />}
                </Helmet>
                <Breadcamp category={{ title: { rendered: 'All Products' }, slug: 'all-products' }} />
                {Object.keys(shopNow).length > 0 && <TopContent language={match.params.language} />}
                <AboutUsBox />
                <ProductsCategories key={uuid()} history={history} subCategories={findSubCategories()} />
                <ValueBox />
            </StyledContainer>
            <MailerLite />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        homePage: state.general.homePage,
        shopNow: state.general.shopNow,
        categories: state.categories,
        subCategories: state.subCategories
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopNow);
