import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import uuid from 'uuid/v4'

import { addCart } from '../../redux/actions/actionCreators'
import { removeProduct, getTotalPrice } from '../../helpers/cartHelpers'

const StyledCartMenu = styled.div`
    visibility: ${props => props.isShown ? 'visible' : 'hidden'};
    opacity: ${props => props.isShown ? '1' : '0'};
    transition: 0.3s;
    background-color: white;
    position: relative;
    top: 15px;
    right: 15px;
    border: 1px solid  rgba(227,227,227,0.9);
    padding: 20px 1%;
    z-index: 3;
    width: 230px;
    border-radius: 0 0 0 3px;
    border-right: 0;
    @media screen and (max-width: 1000px) {
        display: none;
    }
    .product-container {
        border-bottom: 1px solid #e2e2e2;
    }
    img {
        width: 50px;
        padding-bottom: 10px;
    }
    button {
        cursor: pointer;
        position: relative;
        top: 15px;
        background-color: white;
        border: none;
        font-size: 1.6rem;
        height: 10px;
        font-weight: 400;
    }
    .product {
        display: flex;
        div {
            display: flex;
            align-items: flex-start;
            img {
                padding-right: 10px;
            }
        }
    }
    p{
        padding-top: 8px;
    }
    h6 {
        padding: 20px 0;
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
    }
    a {
        color: white;
        padding: 6px;
        margin: 0 35px;
        border-radius: 3px;
        background: linear-gradient(#00bf58,#00bf58d9);
        border-color: rgba(10,10,10,0);
        border-radius: 8px;
        box-shadow:rgb(136, 136, 136) 2px 2px 1px 0px;
    }
`

const CartMenu = props => {
    const { isShown, setIsShown, products, cart, match, addCart } = props;
    return (
        <StyledCartMenu
            isShown={isShown}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            {Object.keys(cart).length && products.slugList.length &&
                <>
                    <div className='product-container'>
                        {Object.entries(cart).map(([key, product]) => (
                            <div key={uuid()} className='product'>
                                <button onClick={() => removeProduct(key, cart, addCart)}>x</button>
                                <div>
                                    <img src={products.images[products.data[product.slug].images[0]].src} alt="" />
                                    <p>{products.data[product.slug].name}{product.attribute ? ` - ${product.attribute}` : ''}</p>
                                    <p>{product.quantity} X {products.data[product.slug].price}€</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <h6>{match.params.language === 'it' ? 'Subtotale' : 'Subtotal'}: {getTotalPrice(cart, products).toFixed(2)}€</h6>
                    <Link to={`/${match.params.language}/cart`} className='cart'>{match.params.language === 'it' ? 'Vai al carrello' : 'View Cart'}</Link>
                </>
            }
        </StyledCartMenu>
    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        products: state.products
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addCart
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartMenu));
