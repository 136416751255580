import React from 'react';
import styled from 'styled-components'


const StyledPaymentMethods = styled.div`
    background-color: white;
    border-bottom: 4px solid #cdd2d2;
    margin: 0 auto;
    width: 100%;
    padding: 20px;
    h3 {
        font-size: 2.1rem;
        font-weight: bold;
        padding-bottom: 30px;
    }
    .icons {
        display: flex;
        flex-wrap: wrap;
        div {
            width: 25%;
            padding-bottom: 10px;
            img {
                display: block;
                margin: auto;
                height: 35px;
                @media screen and (max-width: 400px) {
                    height: 25px;
                }
            }
        }
    }
`

const PaymentMethodsTab = props => {
    const { language } = props;

    return (
        <StyledPaymentMethods>
            <h3>{language === 'it' ? 'Paga in Sicurezza' : 'Secure Payment'}</h3>
            <div className='icons'>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Paypal.png" alt="" />
                </div>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Visa.png" alt="" />
                </div>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Mastercard.png" alt="" />
                </div>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/American-Express.png" alt="" />
                </div>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Maestro.png" alt="" />
                </div>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Visa-Electron.png" alt="" />
                </div>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Postepay.png" alt="" />
                </div>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Visa-Debit.png" alt="" />
                </div>
            </div>
        </StyledPaymentMethods>
    )
}

export default PaymentMethodsTab;