import React from "react";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import itaFlag from '../assets/italy.svg'
import ukFlag from '../assets/united-kingdom.svg'

import { setVisibleCategories, setVisibleSubCategories, setVisibleGeneral, addGeneralFaq, addShopNow, addHeader, addProductPage } from '../redux/actions/actionCreators'
import { getVisibleCategories } from '../helpers/getVisible'
import { findAttr, findFaq } from '../redux/helpers'

const StyledSwitchLang = styled.aside`
    height: ${props => props.isOpen ? '115px' : '0'};
    visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
    overflow: hidden;
    opacity: ${props => props.isOpen ? '1' : '0'};
    transition: height 0s, visibility 1s, opacity .5s;
    position: absolute;
    font-size: 1.3rem;
    top: 113px;
    left: 8%;
    width: 55%;
    background-color: black;
    padding: 15px ;
    border: 2px solid white;
    @media screen and (max-width: 1000px) {
        left: 4%;
        width: 57.5%;
    }
    @media screen and (max-width: 550px) {
        left: 10%;
        width: 54%;
    }
    .ita {
        padding-top: 10px;     
    }
    a {
        justify-content: center;
        img {
            padding-left: 20px;
            padding-right: 0 !important;
        }
    }
`

const SwitchLang = props => {
    const { location, general, categories, subCategories, setVisibleGeneral, setVisibleCategories, setVisibleSubCategories, addGeneralFaq, addShopNow, addHeader, addProductPage, match, isOpen, setIsOpen } = props;
    const cleanUrl = () => {
        return location.pathname.slice(4)
    }

    const onClick = language => {
        setVisibleCategories(getVisibleCategories(categories, language))
        setVisibleSubCategories(getVisibleCategories(subCategories, language))
            if (match.params.language !== 'it') {
                setVisibleGeneral('general-it')
                addGeneralFaq(findFaq(general.data['general-it'], 'faq_'))
                addShopNow(findAttr(general.data['general-it'], 'shop_now_'))
                addHeader(findAttr(general.data['general-it'], 'header_'))
                addProductPage(findAttr(general.data['general-it'], 'product_page_'))
            } else {
                setVisibleGeneral("general")
                addGeneralFaq(findFaq(general.data["general"], 'faq_'))
                addShopNow(findAttr(general.data["general"], 'shop_now_'))
                addHeader(findAttr(general.data["general"], 'header_'))
                addProductPage(findAttr(general.data["general"], 'product_page_'))
            }
        setIsOpen(false)
    }

    return (
        <StyledSwitchLang isOpen={isOpen}>
            <Link className='eng' onClick={() => onClick('en')} to={`/en/${cleanUrl()}`}>
                English
                <img src={ukFlag} alt=""/>
            </Link>
            <Link className='ita' onClick={() => onClick('it')} to={`/it/${cleanUrl()}`}>
                Italiano
                <img src={itaFlag} alt=""/>    
            </Link>
        </StyledSwitchLang>
    )
}

function mapStateToProps(state) {
    return {
        categories: state.categories,
        subCategories: state.subCategories,
        general: state.general
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setVisibleCategories,
            setVisibleSubCategories,
            setVisibleGeneral,
            addGeneralFaq,
            addHeader,
            addShopNow,
            addProductPage
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SwitchLang));
