import React from 'react';
import styled from 'styled-components'

import infoIcon from '../../assets/info-icon.png'

const StyledMofo = styled.div`
    display: flex;
    margin-top: 15px;
    margin-left: 50px;
    @media screen and (max-width: 1000px) {
        margin-left: 25px;
    }
    img {
        height: 13px;
        margin-right: 5px;
    }
    p {
        color: #2f82ce;
        font-size: 1.4rem;
    }
`

const MofoPhrase = props => {
    const { language } = props;
    return (
        <StyledMofo>
            <img src={infoIcon} alt="" />
            <p>
                {language === 'it' ?
                    'Gli articoli non si possono riservare, non farteli scappare!' :
                    "Articles can not be reserved, don't miss them!"}
            </p>
        </StyledMofo>
    )
}

export default MofoPhrase;