import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledCartEmpty = styled.div`
    img {
        display: block;
        margin: 50px auto;
        height: 150px;
    }
    h2 {
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
    }
    a {
        display: block;
        text-align: center;
        cursor: pointer;
        margin: 50px auto;
        width: 300px;
        font-size: 1.6rem;
        font-weight: bolder;
        background: linear-gradient(#00af6b,#00af6be0);
        padding: 13px;
        border-color: rgba(10,10,10,0);
        border-radius: 8px;
        box-shadow:rgb(136, 136, 136) 2px 2px 1px 0px;
        color: white;
        :hover{
            background: linear-gradient(#008a54, #008a54d9);
        }
    }
`
const CartEmpty = props => {
    const { language } = props;
    return (
        <StyledCartEmpty>
            <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/02/Shipping-Bag-YY.jpg" alt='' />
            <h2>{language === 'it' ? 'Il tuo carrello è vuoto' : 'Your cart is empty'}</h2>
            <Link to={`/${language}/shop_now`}>{language === 'it' ? 'Torna allo shopping' : 'Go back to shopping'}</Link>
        </StyledCartEmpty>
    )
}

export default CartEmpty;