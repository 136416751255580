import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { withRouter } from "react-router";

import { getProductsNumber, getTotalPrice } from '../../helpers/cartHelpers'

import cartIcon from '../../assets/cart-icon.png'
import CartMenu from './CartMenu';

const StyledCart = styled.aside`
    position: sticky;
    top: 30px;
    right: 0;
    z-index: 3;
    @media screen and (max-width: 1000px) {
        top: 110px;
    }
    @media screen and (max-width: 650px) {
        top: 59px;
    }
    .inner-container{
        position: absolute;
        height: 55px;
        top: 0;
        transition: 0.3s;
        right:0px;
        background-color: rgba(227,227,227,0.9);
        color: #6f6f6f;
        font-size: 1.3rem;
        width: 230px;
        padding: 15px;
        font-size: 1.3rem;
        border-radius: 3px 0 0 3px;
        @media screen and (max-width: 1000px) {
            top: 0;
            right: 0;
            width: 150px;
        }
        @media screen and (max-width: 650px) {
            height: 41px;
            width: 120px;
            padding: 10px;
            font-size: 1.1rem;
        }
        a{
            display: flex;
            align-items: center;
            justify-content: space-around;
            img {
                height: 25px;
                @media screen and (max-width: 650px) {
                    height: 20px;
                }
            }
        }
    }
`

const Cart = props => {
    const { cart, products, match } = props;
    const [isShown, setIsShown] = useState(false);
    const location = useLocation()


    if (location.pathname !== '/en/cart' && location.pathname !== '/it/cart') {
        return (
            <>
                {
                    Object.keys(cart).length > 0 && products.slugList.length > 0 &&
                    <StyledCart
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                    >
                        <div className='inner-container'>
                            <Link to={`/${match.params.language}/cart`}>
                                <img src={cartIcon} alt="" />
                                <p>{getProductsNumber(cart)} {match.params.language === 'it' ? 'prodotti' : 'items'}: {getTotalPrice(cart, products).toFixed(2)}€</p>
                            </Link>
                            <CartMenu isShown={isShown} setIsShown={setIsShown} />
                        </div>
                    </StyledCart>
                }
            </>
        )
    }
    return null;
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        products: state.products
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));