import React, { useState } from 'react';
import styled from 'styled-components';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import arrowUp from '../../assets/arrowUp.png'

const StyledSTT = styled.aside`
    position: fixed;
    cursor: pointer;
    bottom: 150px;
    right: ${props => props.isShown ? '0' : '-45px'};;
    background-color: #0A0A0A4D;
    padding: 10px;
    border: 1px solid white;
    transition: 1s;
    img {
        height: 20px;
    }
`

const ScrollToTopUI = props => {

    const [isShown, setIsShown] = useState(false)

    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < -500) {
            setIsShown(true)
        } else {
            setIsShown(false)
        }
      })
    return (
        <StyledSTT onClick={() =>  window.scrollTo({top: 0, left: 0, behavior: "smooth"})} isShown={isShown}>
            <img src={arrowUp} alt=""/>
        </StyledSTT>
    )
}

export default ScrollToTopUI;