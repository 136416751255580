import React from 'react';
import styled from 'styled-components';

const url = `${process.env.REACT_APP_API_URL}`

const StyledRefresh = styled.section`
    text-align: center;
    h1{
        font-size: 7rem;
        padding: 80px 40px 40px 40px;
    }
    p{
        font-size: 3rem;
        padding: 0 40px 80px 40px
    }
    a{
        margin: 0 40px 80px 40px;
        font-size: 2.4rem;
        background-color: #00af50;
        padding: 10px 15px;
        border-color: rgba(10,10,10,0);
        border-radius: 8px;
        box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
        color: white;
        max-width: 85%;
        cursor: pointer;
        :hover{
            background-color: #1fd974bd;
        }
    }

`

const RefreshPage = props => {
    const { match, lastURL } = props;
    const isValidURL = lastURL !== '/en/error' && lastURL !== '/it/error'
    let language = ''
    if (match.params.language) {
        language = match.params.language
    } else {
        language = 'it'
    }
    
    return (
        <StyledRefresh>
            <h1>{language === 'it' ? 'Oops, qualcosa è andato storto ' : 'Oops, something went wrong..'}</h1>
            <p>{language === 'it' ? 'Non ti preoccupare, premi il bottone qua sotto' : "Don't worry, click the button below"}</p>
            <a href={isValidURL ? `${url}${lastURL}` : 'https://www.yogicyantra.com'}>{match.params.language === 'it' ? 'Riprova' : 'Try Again'}</a>
        </StyledRefresh>
    )
}

export default (RefreshPage)