import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Loader from "./components/Loader";
import LanguageRedirection from "./components/LanguageRedirection";
import Home from "./pages/Home";

import { fetchingApi, addCart } from "./redux/actions/actionCreators";

import RefreshPage from "./pages/RefreshPage";

function App(props) {
  const { fetchingApi, addCart, products, error } = props;
  const [lastURL, setLastURL] = useState("");

  const history = useHistory();
  useEffect(() => {
    fetchingApi();
    if (localStorage.getItem("cart")) {
      if (Array.isArray(JSON.parse(localStorage.getItem("cart")))) {
        localStorage.removeItem("cart");
      } else {
        addCart(JSON.parse(localStorage.getItem("cart")));
      }
    }

    if (history.location.pathname !== "/en/error" || "/it/error") {
      setLastURL(history.location.pathname);
    }
    // eslint-disable-next-line
  }, []);

  if (products.slugList.length === 0) {
    return (
      <div className="App">
        {!error && <Loader language="en" page="fetching" />}
        <Switch>
          <Route
            path="/how-to-choose-the-right-mala-for-your-personality-type"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/en/how-to-choose-the-right-mala-for-your-personality-type")
            }
          />
          <Route
            path="/7-natural-solutions-to-feminine-frigidity/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/en/7-natural-solutions-to-feminine-frigidity/")
            }
          />
          <Route
            path="/best-crystal-yoni-egg-review-jade-rose-quartz-amethyst/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/en/best-crystal-for-a-yoni-egg-a-review-on-jade-rose-quartz-and-amethyst/")
            }
          />
          <Route
            path="/choose-yoni-egg-right-size/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/en/how-to-choose-your-yoni-egg-which-is-the-right-size/")
            }
          />
          <Route
            path="/rudraksha-mala-beads-legends-benefits-uses/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/en/rudraksha-mala-beads-legends-benefits-and-uses/")
            }
          />
          <Route
            path="/partner-yoga-secret-path-grow-together/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/en/partner-yoga-the-secret-path-to-grow-together/")
            }
          />
          <Route
            path="/best-material-yoga-mat/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/en/what-is-the-best-material-for-a-yoga-mat-cork/")
            }
          />
          <Route
            path="/it/segreto-uovo-giada/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/segreto-uovo-di-giada/")
            }
          />
          <Route
            path="/it/riabilitazione-pavimento-pelvico/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/riabilitazione-pavimento-pelvico-yoni-egg/")
            }
          />
          <Route
            path="/it/esercizi-kegel-come-fare/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/esercizi-kegel-yoni-egg/")
            }
          />
          <Route
            path="/it/come-scegliere-il-mala-giusto-per-la-tua-personalita/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/come-scegliere-mala/")
            }
          />
          <Route
            path="/it/quale-pietra-per-lo-yoni-egg-giada-quarzo-rosa-o-ametista-2/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/quale-pietra-yoni-egg-giada-quarzo-rosa-ametista/")
            }
          />
          <Route
            path="/it/come-scegliere-il-tuo-primo-yoni-egg-quale-misura/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/come-scegliere-misura-yoni-egg/")
            }
          />
          <Route
            path="/it/yoga-in-coppia-praticare-in-due-per-crescere-insieme/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/yoga-in-coppia-praticare-in-due-per-crescere-insieme/")
            }
          />
          <Route
            path="/it/i-semi-di-rudraksha-leggende-benefici-e-usi/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/semi-rudraksha-leggende-benefici-come-usarli/")
            }
          />
          <Route
            path="/it/conosci-mala-da-meditazione-ecco-come-si-usano/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/conosci-i-mala-da-meditazione-ecco-come-si-usano/")
            }
          />
          <Route
            path="/it/sughero-e-yoga-una-coppia-perfetta/"
            render={() =>
              (window.location =
                "https://blog.yogicyantra.com/blog/qual-e-il-materiale-migliore-per-un-materassino-da-yoga-il-sughero/")
            }
          />
          <Route
            path="/ideas/"
            render={() =>
              (window.location = "https://blog.yogicyantra.com/blog/")
            }
          />
          <Route
            path="/:language/error"
            render={(props) => <RefreshPage {...props} lastURL={lastURL} />}
          />
          {error && <Redirect to={`/en/error`} />}
        </Switch>
      </div>
    );
  }

  return (
    <div className="App">
      <Route path="/:language" render={(props) => <Home {...props} />} />
      <Route exact path="/" component={LanguageRedirection} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    error: state.fetching.error,
    loading: state.fetching.loading,
    products: state.products,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchingApi,
      addCart,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
