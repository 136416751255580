import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uuid from 'uuid/v4'

import QAndA from './QAndA'

import { findFaq } from '../../redux/helpers'

const StyledFaq = styled.section`
    padding-top: 20px;
    h3 {
        padding: 30px 0;
        font-size: 3.4rem;
        text-align: center;
        @media screen and (max-width: 650px) {
            font-size: 2.6em;
            padding: 20px 0;
        }
    }
`


const Faq = props => {
    const { generalFaq, category, showGeneral } = props;

    return (
        <StyledFaq>
            <h3>Frequently Asked Questions</h3>
            {showGeneral && generalFaq.map(qAndA => (
                <QAndA key={uuid()} qAndA={qAndA} />
            ))}
            {findFaq(category) && findFaq(category).map(qAndA => (
                <QAndA key={uuid()} qAndA={qAndA} />
            ))}
        </StyledFaq>
    )
}

function mapStateToProps(state) {
    return {
        generalFaq: state.general.generalFaq,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);