import { combineReducers } from 'redux';

import * as types from '../../actions/actionTypes'

const shippingAll = (state = [], action) => {
    switch (action.type) {
        case types.ADD_SHIPPING_METHODS:
            return action.payload.all
        default:
            return state;
    }
} 


const shippingIt = (state = [], action) => {
    switch (action.type) {
        case types.ADD_SHIPPING_METHODS:
            return action.payload.it
        default:
            return state;
    }
} 

const shippingEu = (state = [], action) => {
    switch (action.type) {
        case types.ADD_SHIPPING_METHODS:
            return action.payload.eu
        default:
            return state;
    }
} 

const shippingUs = (state = [], action) => {
    switch (action.type) {
        case types.ADD_SHIPPING_METHODS:
            return action.payload.us
        default:
            return state;
    }
} 

const shippingSelected = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_SELECTED_SHIPPING:
            return action.payload
        default:
            return state;
    }
}

const shippingMethods = combineReducers({
    shippingAll,
    shippingIt,
    shippingEu,
    shippingUs,
    shippingSelected
})

export default shippingMethods;