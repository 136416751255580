import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uuid from 'uuid/v4'

import ProductsCards from './ProductsCards';


const StyledProductsCategories = styled.section`

`

const ProductsCAtegories = props => {
    const { subCategories, history } = props;

    return (
        <StyledProductsCategories>
            {subCategories && subCategories.map(subCategory => <ProductsCards key={uuid()} history={history} subCategory={subCategory} />)}
        </StyledProductsCategories>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCAtegories);