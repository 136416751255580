import { combineReducers } from 'redux';

import * as types from '../../actions/actionTypes'

const idList = (state = [], action) => {
    switch (action.type) {
        case types.ADD_VARIATONS:
            return [...state, action.payload.result].flat();
        default:
            return state;
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_VARIATONS:
            return Object.assign(state, action.payload.entities.variatons);
        default:
            return state;
    }
}

const selectedVariation = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_SELECTED_VARIATION:
            return action.payload;
        default:
            return state;
    }
}

const variations = combineReducers({
    idList,
    data,
    selectedVariation
})

export default variations;