import { combineReducers } from 'redux';

import * as types from '../../actions/actionTypes'

const couponsList = (state = [], action) => {
    switch (action.type) {
        case types.ADD_COUPONS:
            return action.payload.result;
        default:
            return state;
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_COUPONS:
            return action.payload.entities.coupons;
        default:
            return state;
    }
}

const appliedCoupon = (state = {}, action) => {
    switch (action.type) {
        case types.SET_APPLIED_COUPON:
            return action.payload;
        default:
            return state;
    }
}

const discount = (state = 0, action) => {
    switch (action.type) {
        case types.ADD_DISCOUNT:
            return action.payload;
        default:
            return state;
    }
}

const coupons = combineReducers({
    couponsList,
    data,
    appliedCoupon,
    discount
})

export default coupons;