import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { addCart, changeAddProdModal } from '../../redux/actions/actionCreators'
import { getCorrectMetaData } from '../../helpers/getCorrectMetaData'
// import findSku from '../../helpers/findSku'
// import { cleanSlug } from '../../helpers/cleanCategories'

// import TrustBox from '../TrustBox'
import Tabs from './Tabs';
import Select from './Select';
import BuyButtons from './BuyButtons';

const StyledBuyCard = styled.div`
    text-align: center;
    width: 49.5%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
    a {
        @media screen and (max-width: 1000px) {
            order: -1;
        }
    }
    h1 {
        font-size: 3.8rem;
        padding-bottom: 25px;
        @media screen and (max-width: 1000px) {
           font-size: 2.4rem;
        }
        @media screen and (max-width: 650px) {
           font-size: 2.2rem;
        }
    }
    .short-description {
        font-size: 1.6rem;
        padding-bottom: 20px;
        @media screen and (max-width: 1000px) {
           display: none;
        }
    }
    .price {
        font-size: 2.5rem;
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
        padding-top: 30px;
        @media screen and (max-width: 1000px) {
            font-size: 2.4rem;
            order: -1;
            padding-top: 10px;
            padding-bottom: 20px;
        }
        @media screen and (max-width: 650px) {
           font-size: 2.2rem;
        }
        span {
            text-decoration-line: line-through;
            padding-right: 5px;
        }
        p {
            font-weight: ${props => props.isDiscounted ? '700' : 'normal'};
        }
    }
    .trustpilot {
        padding-bottom: 25px;
    }
    img {
        width: 100%;
    }
`
// const StyledTrustBox = styled.div`
//     @media screen and (max-width: 1000px) {
//         padding-bottom: 20px;
//     }
//     @media screen and (max-width: 650px) {
//         pointer-events: none;
//     }
// `
const BuyCard = props => {
    const { products, product, subCategory, category, productPage, match, media, attributeSelected, setAttributeSelected, buyNow, stockStatus, variations } = props;

    const [isDiscounted, setisDiscounted] = useState(false)

    useEffect(() => {
        if (product.sale_price) {
            setisDiscounted(true)
        }
    }, [product.sale_price])

    useEffect(() => {
        if (product.attributes.length > 0) {
            if (match.params.language === 'it') {
                const attribute = product.attributes.filter(attribute => attribute.name.endsWith('_it'))[0]
                setAttributeSelected(attribute.options[0])
            } else {
                const attribute = product.attributes.filter(attribute => !attribute.name.endsWith('_it'))[0]
                setAttributeSelected(attribute.options[0])
            }
        }
        // eslint-disable-next-line
    }, [])

    return (
        <StyledBuyCard isDiscounted={isDiscounted} >
            <h1>{match.params.language === 'it' ? getCorrectMetaData(product, products, 'name_it') : product.name}</h1>
            <p className='short-description'>{match.params.language === 'it' ? getCorrectMetaData(product, products, 'short_description_it') : getCorrectMetaData(product, products, 'short_description')}</p>
            {/* <StyledTrustBox>
                <TrustBox attributes={{
                    locale: `${match.params.language === 'it' ? 'it-IT' : 'en-GB'}`, template: "54d39695764ea907c0f34825", height: "24px", width: "100%", theme: "light", stars: "4,5", sku: findSku(cleanSlug(category), products)
                }} />
            </StyledTrustBox> */}
            <div className='price'>
                {product.type === 'simple' && product.sale_price && <span>{product.regular_price}€</span>}
                {product.type === 'simple' && <p>{product.price} €</p>}
                {product.type === 'variable' && variations.idList.length > 0 && variations.data[product.variations[0]].sale_price && <span>{variations.data[product.variations[0]].regular_price} €</span>}
                {product.type === 'variable' && variations.idList.length > 0 && <p>{variations.data[product.variations[0]].price} €</p>}
            </div>
            <Select product={product} attributeSelected={attributeSelected} setAttributeSelected={setAttributeSelected} />
            <BuyButtons product={product} attributeSelected={attributeSelected} category={category} buyNow={buyNow} stockStatus={stockStatus} />
            {media.data[productPage.blurp_img.ID] && <img src={productPage.blurp_img.guid} alt={match.params.language === 'it' ? media.data[productPage.blurp_img.ID].alternative_text_it : media.data[productPage.blurp_img.ID].alt_text} />}
            <Tabs product={product} subCategory={subCategory} />

        </StyledBuyCard>
    )
}

function mapStateToProps(state) {
    return {
        products: state.products,
        productPage: state.general.productPage,
        cart: state.cart,
        media: state.media,
        variations: state.variations
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addCart,
            changeAddProdModal
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyCard))