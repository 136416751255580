import * as types from '../actions/actionTypes'

export const fetching = (state = { error: null, loading: false }, action) => {
    switch (action.type) {
        case types.FETCHING_API:
            return { ...state, loading: true };
        case types.FETCH_SUCCESS:
            return { ...state, error: null, loading: false, };
        case types.FETCH_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}

export const country = (state = '', action) => {
    switch (action.type) {
        case types.ADD_LOCATION:
            return action.payload;
        default:
            return state;
    }
}


export const addProdModal = (state = false, action) => {
    switch (action.type) {
        case types.CHANGE_ADD_PROD_MODAL:
            return action.payload;
        default:
            return state;
    }
}