import { combineReducers } from 'redux';
import { fetching, country, addProdModal } from './reducers';
import products from './products/products';
import categories from './categories/categories'
import subCategories from './subCategories/subCategories';
import general from './general/general';
import { cart } from './cart/cart'
import shippingMethods from './shippingMethods/shippingMethods'
import media from './media/media'
import variations from './variations/variatons'
import coupons from './coupons/coupons'
import tags from './tags/tags'
import referralLP from './referralLP/referralLP';
import leaflet from './leaflet/leaflet';

const rootReducer = combineReducers({
    fetching,
    products,
    categories,
    subCategories,
    general,
    cart,
    country,
    shippingMethods,
    media,
    variations,
    coupons,
    tags,
    referralLP,
    addProdModal,
    leaflet
});

export default rootReducer;