import { combineReducers } from 'redux';

import * as types from '../../actions/actionTypes'

const slugList = (state = [], action) => {
    switch (action.type) {
        case types.ADD_LEAFLET:
            return action.payload.result;
        default:
            return state;
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_LEAFLET:
            return action.payload.entities.leaflet;
        default:
            return state;
    }
}

const visibles = (state = [], action) => {
    switch (action.type) {
        case types.SET_VISIBLE_LEAFLET:
            return action.payload
        default:
            return state;
    }
}

const leaflet = combineReducers({
    slugList,
    visibles,
    data
})

export default leaflet;