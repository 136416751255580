import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'

import infoIcon from '../../assets/info-icon.png'

import { getTotalPrice } from '../../helpers/cartHelpers'

const StyledFreeShipping = styled.div`
    background-color: white;
    border-bottom: 4px solid #cdd2d2;
    padding: 10px 20px;
    .top{
        display: flex;
        width: 100%;
        padding-bottom: 10px;
        img {
            height: 13px;
            margin-right: 5px;
        }
        p {
            color: #2f82ce;
            font-size: 1.4rem;
            margin-top: 1px;
            font-weight: bolder;
            /* text-decoration: underline; */
        }
    }
    .bar{
        height: 15px;
        border: 1px solid black;
        border-radius: 25px;
        .filler{
            background: linear-gradient(90deg, rgba(47,97,206,1) 0%, rgba(47,130,206,1) 35%, rgba(47,201,206,1) 100%);
            margin-top: 1px;
            margin-left: 1px;
            height: 11px;
            width: ${props =>  `${props.percentage}%`};
            border-radius: 25px 0 0 25px;
            transition: 0.4s;
        }
    }
`

const FreeShippingTab = props => {
    const { match, products, cart, shippingMethods, coupons } = props;
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        setPercentage(getMissingPercentage() > 65 ? getMissingPercentage(): 65)
        // eslint-disable-next-line
    })

    const getShippingDifference = () => {
        return parseInt(shippingMethods.shippingSelected[1].settings.min_amount.value) - (getTotalPrice(cart, products) - coupons.discount)
    }

    const getMissingPercentage = () => {
        return 100 - (100/parseInt(shippingMethods.shippingSelected[1].settings.min_amount.value) * getShippingDifference())
    }

    return (
        <StyledFreeShipping percentage={percentage}>
            {shippingMethods.shippingSelected.length &&
                <>
                    <div className='top'>
                        <img src={infoIcon} alt="" />
                        <p>{match.params.language === 'it' ? `Mancano solo ${getShippingDifference().toFixed(2)}€ per le spedizioni gratuite` : `You only need ${getShippingDifference().toFixed(2)}€ to be eligible for free shipping`}</p>
                    </div>
                    <div className='bar'>
                        {/* some browsers doesn't display empty div */}
                        <div className='filler'>.</div> 
                    </div>
                </>
            }
        </StyledFreeShipping>
    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        products: state.products,
        shippingMethods: state.shippingMethods,
        coupons: state.coupons
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FreeShippingTab));