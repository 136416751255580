import React from 'react';

const TrustBox = props => {
    const { attributes } = props;
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    
    if (Object.keys(attributes).length > 0) {
        return (
            <div ref={ref} className="trustpilot-widget" data-locale={attributes.locale} data-template-id={attributes.template} data-businessunit-id="5b7b4470d47ab900019911dc" data-style-height={attributes.height} data-style-width={attributes.width} data-theme={attributes.theme} data-tags={attributes.tags} data-stars="4,5" data-sku={attributes.sku ? attributes.sku : ''} data-name={attributes.name} data-review-languages={attributes.language ? attributes.language : ''}>
                <a href="https://www.trustpilot.com/review/www.yogicyantra.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
            </div>
        );
    } 
    return null;
};
export default TrustBox;