import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const StyledBlurpsTop = styled.section`
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 50px;
    @media screen and (max-width: 850px) {
        flex-wrap: wrap;
        width: 90%;
    }
    @media screen and (max-width: 650px) {
        padding-top: 0;
        padding-bottom: 20px;
    }
    .blurp_top {
        display: flex;
        width: 28%;
        padding: 0 5px;
        @media screen and (max-width: 850px) {
            width: 45%;
            padding-top: 30px;
        }
        img {
            height: 30px;
            padding-right: 10px;
            @media screen and (max-width: 650px) {
                height: 25px;
            }
        }
        h2 {
            font-size: 2.4rem;
            padding-bottom: 15px;
            @media screen and (max-width: 650px) {
                font-size: 2.2rem;
            }
        }
        p {
            font-size: 1.6rem;
            line-height: 1.7;
            font-style: italic;
            @media screen and (max-width: 650px) {
                font-size: 1.4rem;
            }
        }
    }
`
const BlurpsTop = props => {
    const { homePage } = props;
    return (
        <StyledBlurpsTop>
            {
                Object.keys(homePage).length > 0 &&
                <>

                    <div className='blurp_top'>
                        <img src={homePage.top_blurp_img_1.guid} alt="" />
                        <div>
                            <h2>{homePage.top_blurp_title_1}</h2>
                            <p>{homePage.top_blurp_text_1}</p>
                        </div>
                    </div>
                    <div className='blurp_top'>
                        <img src={homePage.top_blurp_img_2.guid} alt="" />
                        <div>
                            <h2>{homePage.top_blurp_title_2}</h2>
                            <p>{homePage.top_blurp_text_2}</p>
                        </div>
                    </div>
                    <div className='blurp_top'>
                        <img src={homePage.top_blurp_img_3.guid} alt="" />
                        <div>
                            <h2>{homePage.top_blurp_title_3}</h2>
                            <p>{homePage.top_blurp_text_3}</p>
                        </div>
                    </div>
                    <div className='blurp_top'>
                        <img src={homePage.top_blurp_img_4.guid} alt="" />
                        <div>
                            <h2>{homePage.top_blurp_title_4}</h2>
                            <p>{homePage.top_blurp_text_4}</p>
                        </div>
                    </div>
                </>
            }
        </StyledBlurpsTop>
    )
}

function mapStateToProps(state) {
    return {
        homePage: state.general.homePage,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BlurpsTop);