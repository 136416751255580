export const findFaq = (obj, startString = "") => {
  const keys = Object.keys(obj)
  const questionsKeys = keys.filter(key => key.startsWith(`${startString}question`))
  const answersKeys = keys.filter(key => key.startsWith(`${startString}answer`))
  const questionsArr = questionsKeys.map(key => obj[key])
  const answerArr = answersKeys.map(key => obj[key])
  const result = []
  for (let i = 0; i < questionsArr.length; i++) {
    result.push([questionsArr[i], answerArr[i]])
  }
  return result
}

export const findAttr = (obj, startString = "") => {
  const keys = Object.keys(obj)
  const filteredKeys = keys.filter(key => key.startsWith(`${startString}`))
  const result = { }
  filteredKeys.map(key => result[key.split(`${startString}`)[1]] = obj[key])
  return result;
}