import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'

const StyledTag = styled.p`
    border: ${props => `1px solid ${props.color}`};
    color: ${props => props.color};
    font-size: .9rem;
    padding: 4px 10px 2px;
    margin: 10px 5px 10px 0;
    border-radius: 26px;
    white-space: nowrap;
    letter-spacing: 1.1px;
    @media screen and (max-width: 1000px) {
        margin-top: 10px;
        font-size: 0.7rem;
        margin: 3px 5px 3px 0;
    }
`

const Tag = props => {
    const {match, tags, tagSlug} = props;
    return (
        <StyledTag color={tags.data[tagSlug].colore}>
            {match.params.language === 'it' ?
                tags.data[tagSlug].name_it :
                tags.data[tagSlug].name}
        </StyledTag>
    )
}

function mapStateToProps(state) {
    return {
        tags: state.tags
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tag));