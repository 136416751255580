import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uuid from 'uuid/v4'


import { cleanSlug } from '../../helpers/cleanCategories'
import ProductCard from '../ProductCard';

const StyledProductsCards = styled.div`
    text-align: center;
    padding-top: 50px;
    .title{
        @media screen and (max-width: 650px){
            border-bottom: 1px solid #e2e2e2
        }
        h2 {
            font-size: 3.6rem;
            padding-bottom: 15px;
            @media screen and (max-width: 650px){
                font-size: 2.6rem;  
                font-weight: bold;  
            }
        }
        .category-description {
            font-size: 2rem;
            padding-bottom: 40px;
            @media screen and (max-width: 650px){
                font-size: 1.6rem;   
            }
        }
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        text-align: center;
    }
`

const ProductsCards = props => {
    const { subCategory, products, history } = props;

    useEffect(() => {
        if (history.location.hash) {
            let element = document.getElementById(history.location.hash.split('#')[1])
            element.scrollIntoView({ behavior: "smooth" })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <StyledProductsCards id={subCategory.slug}>
            <div className='title'>
                <h2>{subCategory.description_main}</h2>
                <p className='category-description'>{subCategory.sub_description}</p>
            </div>
            <div className='container'>
                {products.slugList.map(slug => {
                    const product = products.data[slug];
                    return product.categories.map(category => {
                        if (category.slug === cleanSlug(subCategory.slug)) {
                            return <ProductCard product={product} key={uuid}/>
                        }
                        return null;
                    })
                })}
            </div>
        </StyledProductsCards>
    )
}

function mapStateToProps(state) {
    return {
        products: state.products,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsCards));