import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { evaluate } from 'mathjs';


import { getTotalPrice, createQueryParam, getFlatRate } from '../../helpers/cartHelpers'

const StyledPrices = styled.div`
    background-color: white;
    border-bottom: 4px solid #cdd2d2;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    @media screen and (max-width: 1000px) {
        order: 1;
    }
    h3 {
        font-size: 2.8rem;
        font-weight: bold;
        padding-bottom: 20px;
    }
    .line {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        font-size: 1.6rem;
    }
    .discount {
        p {
            font-weight: bolder;
            color: green;
        }
    }
    .discounted {
        display: flex;
        justify-content: flex-end;
        .regular-value {
            text-decoration: line-through;
        }
        .discounted-value {
            color: green;
            font-weight: bold;
            padding-left: 10px;
        }
    }

    .totals {
        border-top: 1px solid #cdd2d2;
        .key, .value {
            font-weight: bolder;
            color: black;
        }
    }
    a {
        display: block;
        text-align: center;
        cursor: pointer;
        margin: 10px auto;
        font-size: 1.6rem;
        font-weight: bolder;
        background: linear-gradient(#00af6b,#00af6be0);
        padding: 13px;
        border-color: rgba(10,10,10,0);
        box-shadow:rgb(136, 136, 136) 2px 2px 1px 0px;
        color: white;
        :hover{
            background: linear-gradient(#008a54, #008a54d9);
        }
    }
    .modal-mobile {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: white;
        padding-left: 20px;
        padding-right: 20px;
        .totals {
            padding-top: 13px;
        }
        @media screen and (max-width: 1000px) {
            display: block;
            visibility: ${props => props.isShown ? 'visible' : 'hidden'};
            opacity: ${props => props.isShown ? '1' : '0'};
            transition: .3s;
        }
    }
`

const PricesTab = props => {
    const { match, products, cart, shippingMethods, getShippingCost, coupons } = props;
    const buttonRef = useRef()
    const [isShown, setIsShown] = useState(true)

    useEffect(() => {
        window.onscroll = () => {
            if (buttonRef.current) {
                if (buttonRef.current.offsetTop > window.innerHeight + window.pageYOffset) {
                    setIsShown(true)
                } else {
                    setIsShown(false)
                }
            }
        }
    }, [])

    return (
        <StyledPrices isShown={isShown} >
            <h3>{match.params.language === 'it' ? 'Totale' : 'Totals'}</h3>
            <div className='subtotal line'>
                <p className='key'>{match.params.language === 'it' ? 'Subtotale' : 'Subtotals'}</p>
                <p className='value'>{getTotalPrice(cart, products).toFixed(2)} €</p>
            </div>
            {Object.keys(coupons.appliedCoupon).length > 0 &&
                evaluate(coupons.appliedCoupon.amount) > 0 &&
                <div className='discount line'>
                    <p className='key'>{match.params.language === 'it' ? 'Sconto' : 'Discount'}</p>
                    <div className='discounted'>
                        <p>{coupons.appliedCoupon.code.toUpperCase()} - </p>
                        <p className='value'>{coupons.discount.toFixed(2)} €</p>
                    </div>
                </div>
            }
            <div className='shipping line'>
                <p className='key'>{match.params.language === 'it' ? 'Spedizione' : 'Shipping'}</p>
                {shippingMethods.shippingSelected.length &&
                    Object.keys(coupons.appliedCoupon) &&
                    getShippingCost() > 0 &&
                    <p className='value'>{getShippingCost().toFixed(2)} €</p>
                }
                {shippingMethods.shippingSelected.length &&
                    Object.keys(coupons.appliedCoupon) &&
                    getShippingCost() === 0 &&
                    <div className='discounted'>
                        <p className='regular-value'>{getFlatRate(shippingMethods.shippingSelected[0]).toFixed(2)} €</p>
                        <p className='discounted-value'>{getShippingCost().toFixed(2)} €</p>
                    </div>
                }
            </div>
            <div className='totals line'>
                <p className='key'>{match.params.language === 'it' ? 'Totale' : 'Totals'}</p>
                {shippingMethods.shippingSelected.length &&
                    Object.keys(coupons.appliedCoupon) &&
                    coupons.discount === 0 &&
                    <p className='value'>{(getTotalPrice(cart, products) + getShippingCost()).toFixed(2)} €</p>}
                {shippingMethods.shippingSelected.length &&
                    Object.keys(coupons.appliedCoupon) &&
                    coupons.discount > 0 &&
                    <div className='discounted'>
                        <p className='regular-value'>{(getTotalPrice(cart, products) + getShippingCost()).toFixed(2)} €</p>
                        <p className='discounted-value'>{(getTotalPrice(cart, products) + getShippingCost() - coupons.discount).toFixed(2)} €</p>
                    </div>}
            </div>
            <a ref={buttonRef} href={match.params.language === 'it' ?
                `https://payments.yogicyantra.com/checkout/${createQueryParam(cart, products, coupons.appliedCoupon)}` :
                `https://payments.yogicyantra.com/checkout/en/?clear-cart&${createQueryParam(cart, products, coupons.appliedCoupon)}`}
                className='green desktop-button'>
                {match.params.language === 'it' ? 'PROCEDI' : 'PROCEED'}
            </a>

            <div className='modal-mobile'>
                <div className='totals line'>
                    <p className='key'>{match.params.language === 'it' ? 'Totale' : 'Totals'}</p>
                    {shippingMethods.shippingSelected.length &&
                        Object.keys(coupons.appliedCoupon) &&
                        coupons.discount === 0 &&
                        <p className='value'>{(getTotalPrice(cart, products) + getShippingCost()).toFixed(2)} €</p>}
                    {shippingMethods.shippingSelected.length &&
                        Object.keys(coupons.appliedCoupon) &&
                        coupons.discount > 0 &&
                        <div className='discounted'>
                            <p className='regular-value'>{(getTotalPrice(cart, products) + getShippingCost()).toFixed(2)} €</p>
                            <p className='discounted-value'>{(getTotalPrice(cart, products) + getShippingCost() - coupons.discount).toFixed(2)} €</p>
                        </div>}
                </div>
                <a href={match.params.language === 'it' ?
                    `https://payments.yogicyantra.com/checkout/${createQueryParam(cart, products, coupons.appliedCoupon)}` :
                    `https://payments.yogicyantra.com/checkout/en/?clear-cart&${createQueryParam(cart, products, coupons.appliedCoupon)}`}
                    className='green desktop-button'>
                    {match.params.language === 'it' ? 'PROCEDI' : 'PROCEED'}
                    
                </a>
            </div>
        </StyledPrices>
    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        products: state.products,
        shippingMethods: state.shippingMethods,
        coupons: state.coupons
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PricesTab));