import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cleanSlug } from '../../helpers/cleanCategories'

const StyledHeader = styled.section`
    background-image: url(${props => props.image});
    height: 550px;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    @media screen and (max-width: 1000px) {
        padding-top: 110px;
    }
    h1 {
        font-size: 5rem;
        padding: 115px 15px 0 15px;
        color: ${props => props.colorValue};
        @media screen and (max-width: 1000px) {
        padding-top:0;
        font-size: 3rem;
        }
    }
    h2 {
        font-size: 2.4rem;
        padding: 10px 15px 0 15px;
        color: ${props => props.colorValue};
        @media screen and (max-width: 1000px) {
            font-size: 1.6rem;
        }
    }
    button {
        font-size: 2.4rem;
        cursor: pointer;
        background: linear-gradient(#00bf58,#00bf58d9);
        margin-top: 60px;
        padding: 10px 15px;
        border-color: rgba(10,10,10,0);
        border-radius: 8px;
        box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
        color: white;
        max-width: 85%;
        transition: filter 200ms ease;
        :hover{
            filter: saturate(60%);
        }
        @media screen and (max-width: 1000px) {
            font-size: 1.6rem;
        }
    }
`

const Header = props => {
    const { category, language, subCategories } = props;

    const getButton = () => {
         const buttonArr = subCategories.visibles.map(slug => {
            if (subCategories.data[slug].parent_slug === category.slug) {
                return (                  
                        <HashLink smooth to={`/${language}/${cleanSlug(category.slug)}#${slug}`}>
                            <button>{category.button_text}</button>
                        </HashLink>
                )
            }
            return null;
        }).filter(button => button )
        return buttonArr[0]
    }
    return (
        <StyledHeader image={category.main_img.guid} colorValue={category.description_color}>
            <h1>{category.description_main}</h1>
            <h2>{category.sub_description}</h2>
            {getButton()}
        </StyledHeader>
    )
}

function mapStateToProps(state) {
    return {
        subCategories: state.subCategories,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);


