import { schema } from 'normalizr';

export const products = new schema.Entity('products', {
    images: [new schema.Entity('images')],
    meta_data: [new schema.Entity('metaData')],
    tags: [new schema.Entity('tags', {}, { idAttribute: 'slug' })]
}, {
    idAttribute: 'slug'
})


export const categories = new schema.Entity('categories', {

}, {
    idAttribute: 'slug'
})

const productImg = new schema.Entity('productImg', {}, { idAttribute: 'ID' })
export const subCategories = new schema.Entity('subCategories', {
    product_page_img: productImg,
}, {
    idAttribute: 'slug'
})

export const general = new schema.Entity('general', {}, { idAttribute: 'slug' })

export const media = new schema.Entity('media', {}, { idAttribute: 'id' })

export const coupons = new schema.Entity('coupons', {}, { idAttribute: 'code' })

export const variatons = new schema.Entity('variatons')

export const tags = new schema.Entity('tags', {}, { idAttribute: 'slug' })

export const referralLP = new schema.Entity('referralLP', {}, { idAttribute: 'slug' })

export const leaflet = new schema.Entity('leaflet', {}, { idAttribute: 'slug' })

