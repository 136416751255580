import React  from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import uuid from 'uuid/v4'

import SingleCategory from './SingleCategory'

const StyledMenu = styled.div`
    overflow: hidden;
    visibility: ${props => props.isShown ? 'visible' : 'hidden'};
    opacity: ${props => props.isShown ? '1' : '0'};
    transition: padding-top .2s, visibility .2s, opacity .2s;
    position: absolute;
    width: 100%;
    top:108px;
    left: 0;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,.1);
    padding: 10px 0;
    z-index: 3;
    @media screen and (max-width: 1100px) {
        top: 108px;
    }
    @media screen and (max-width: 650px) {
        top: 57px;
    }
    li {
        text-align: left;
        padding: 8px 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        a {
            display: block;
            font-size: 1.4rem;
        }
    }
`

const MobileMenu = props => {
    const { isShown, language, categories, toogleMenu } = props;
    return (
        <StyledMenu isShown={isShown}>
            <ul >
                <li onClick={toogleMenu}><Link to={`/${language}/shop_now`}>{language === 'it' ? 'TUTTI I PRODOTTI' : 'ALL PRODUCTS'}</Link></li>
                {categories.visibles.map(slug =>
                    <SingleCategory key={uuid()} language={language} category={categories.data[slug]} toogleMenu={toogleMenu} screen='mobile' />
                )}
                <li className='blog'><a href="https://www.yogicyantra.com/ideas/">Blog</a></li>
            </ul>
        </StyledMenu>
    )
}

function mapStateToProps(state) {
    return {
        subCategories: state.subCategories,
        categories: state.categories,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
