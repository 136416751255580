import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'
import { evaluate } from 'mathjs';

import { getTotalPrice, getFlatRate } from '../../helpers/cartHelpers'
import { addDiscount } from '../../redux/actions/actionCreators'

import PricesTab from './PricesTab';
import FreeShippingTab from './FreeShippingTab';
import DeliveryWithinTab from './DeliveryWithinTab';
import PaymentMethods from './PaymentMethods';
import CouponsTab from './CouponsTab';
import SuggestedProduct from './SuggestedProduct';

const StyledTotals = styled.div`
    width: 400px;
    border: 6px solid #cdd2d2;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`

const TotalsTab = props => {
    const { products, cart, shippingMethods, match, coupons, addDiscount } = props;

    useEffect(() => {
        if (Object.keys(coupons.appliedCoupon).length) {
            if (coupons.appliedCoupon.discount_type === 'fixed_cart') {
                addDiscount(evaluate(coupons.appliedCoupon.amount))
            } else {
                addDiscount(getTotalPrice(cart, products) / 100 * evaluate(coupons.appliedCoupon.amount))
            }
        }
    // eslint-disable-next-line
    }, [coupons.appliedCoupon])

    const getShippingCost = () => {
        if ((getTotalPrice(cart, products) > parseInt(shippingMethods.shippingSelected[1].settings.min_amount.value)) ||
            coupons.appliedCoupon.free_shipping) {
            return 0
        }
        return getFlatRate(shippingMethods.shippingSelected[0])
    }

    return (
        <StyledTotals>
            <PricesTab getShippingCost={getShippingCost} />
            {shippingMethods.shippingSelected.length &&
                Object.keys(coupons.appliedCoupon) &&
                getShippingCost() > 0 &&
                <FreeShippingTab />
            }
            <SuggestedProduct />
            <DeliveryWithinTab language={match.params.language} />
            <PaymentMethods language={match.params.language} />
            <CouponsTab />
        </StyledTotals>
    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        products: state.products,
        shippingMethods: state.shippingMethods,
        coupons: state.coupons
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addDiscount
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TotalsTab));