import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import uuid from "uuid/v4";

import { getCorrectMetaData } from "../helpers/getCorrectMetaData";

const StyledProductCard = styled.article`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 60px;
  width: 45%;
  position: relative;
  height: 650px;
  @media screen and (max-width: 800px) {
    width: 90%;
  }
  @media screen and (max-width: 400px) {
    height: 530px;
  }
  .tags-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .img-container {
    position: relative;
    height: 350px;
    @media screen and (max-width: 400px) {
      height: 230px;
    }
    img {
      position: absolute;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
    .product-image {
      opacity: 1;
      transition: 0.3s ease-in-out;
      :hover {
        opacity: 0;
      }
    }
    .product-image-hover {
      opacity: 0;
      transition: 0.3s ease-in-out;
      :hover {
        opacity: 1;
      }
    }
    .out-of-stock {
      position: absolute;
      height: 100px;
      width: auto;
      bottom: 10px;
      right: 0;
      left: auto;
    }
    .discount-sticker {
      position: absolute;
      width: auto;
      bottom: 20px;
      right: 10px;
      left: auto;
      background-color: ${(props) => props.discountColor};
      color: white;
      font-size: 1.6rem;
      font-weight: bolder;
      padding: 16px 5px;
      border-radius: 50%;
    }
    .black-friday-sticker {
      position: relative;
      background-image: url("https://payments.yogicyantra.com/wp-content/uploads/2021/11/Black-Friday-label-2.png");
      height: 40px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  h3 {
    font-size: 2.9rem;
    padding-bottom: 20px;
    @media screen and (max-width: 650px) {
      font-size: 2.2rem;
    }
  }
  p {
    font-size: 1.8rem;
    padding-bottom: 35px;
    @media screen and (max-width: 650px) {
      font-size: 1.5rem;
    }
  }
  .price {
    display: flex;
    justify-content: center;
    span {
      font-size: 1.8rem;
      text-decoration-line: line-through;
      padding-right: 5px;
    }
    p {
      font-weight: 700;
      @media screen and (max-width: 650px) {
        font-size: 1.8rem;
      }
    }
  }
  button {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    bottom: 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 2.4rem;
    background: linear-gradient(#00af6b, #00af6be0);
    margin-top: 25px;
    padding: 2%;
    border-color: rgba(10, 10, 10, 0);
    border-radius: 8px;
    box-shadow: rgb(136, 136, 136) 2px 2px 1px 0px;
    color: white;
    :hover {
      background: linear-gradient(#008a54, #008a54d9);
    }
    @media screen and (max-width: 650px) {
      bottom: 55px;
      font-size: 1.8rem;
      padding: 2% 10%;
    }
  }
`;

const StyledTags = styled.p`
  border: ${(props) => `1px solid ${props.color}`};
  color: ${(props) => props.color};
  font-size: 0.9rem !important;
  padding: 4px 10px 2px !important;
  margin: 5px 5px;
  border-radius: 26px;
  white-space: nowrap;
  letter-spacing: 1.1px;
  @media screen and (max-width: 650px) {
    font-size: 0.7rem !important;
    margin: 0 5px;
  }
`;

const ProductCard = (props) => {
  const { match, products, product, tags, media, language, variations } = props;
  let lang;
  if (language) {
    lang = language;
  } else {
    lang = match.params.language;
  }

  const getDiscountPercentage = (salePrice, regularPrice) => {
    if (salePrice) {
      return Math.floor(100 - (salePrice * 100) / regularPrice);
    }
  };

  const getDiscountColor = () => {
    let percent = 0;
    if (product.type === "variable" && variations.idList.length > 0) {
      percent = getDiscountPercentage(
        variations.data[product.variations[0]].sale_price,
        variations.data[product.variations[0]].regular_price
      );
    } else {
      percent = getDiscountPercentage(
        product.sale_price,
        product.regular_price
      );
    }

    if (percent < 16) {
      return "#F6C100";
    } else if (percent < 26) {
      return "#FE9149";
    } else {
      return "#FF4D48";
    }
  };

  return (
    <StyledProductCard
      productImg={products.images[product.images[0]].src}
      productImgHover={products.images[product.images[1]].src}
      discountColor={getDiscountColor()}
    >
      <Link to={`/${lang}/${product.slug}`} key={uuid()} className="card">
        {tags.slugList.length > 0 && (
          <div className="tags-container">
            {products.data[product.slug].tags.map((slug) => (
              <StyledTags key={uuid()} color={tags.data[slug].colore}>
                {lang === "it" ? tags.data[slug].name_it : tags.data[slug].name}
              </StyledTags>
            ))}
          </div>
        )}
        <div className="img-container">
          {media.data[product.images[0]] && (
            <img
              className="product-image"
              src={products.images[product.images[0]].src}
              alt={
                lang === "it"
                  ? media.data[product.images[0]].alternative_text_it
                  : media.data[product.images[0]].alt_text
              }
            />
          )}
          {media.data[product.images[1]] && (
            <img
              className="product-image-hover"
              src={products.images[product.images[1]].src}
              alt={
                lang === "it"
                  ? media.data[product.images[1]].alternative_text_it
                  : media.data[product.images[1]].alt_text
              }
            />
          )}
          {product.stock_status === "outofstock" && lang === "en" && (
            <img
              className="out-of-stock"
              src="https://payments.yogicyantra.com/wp-content/uploads/2021/01/BLURP-PRODOTTO-OUT-OF-STOCK.png"
              alt="Out Of Stock"
            />
          )}
          {product.stock_status === "outofstock" && lang === "it" && (
            <img
              className="out-of-stock"
              src="https://payments.yogicyantra.com/wp-content/uploads/2021/01/BLURP-PRODOTTO-ESAURITO.png"
              alt="Prodotto Esaurito"
            />
          )}
          {product.sale_price && product.stock_status != "outofstock" && (
            <p className="discount-sticker">
              -
              {getDiscountPercentage(product.sale_price, product.regular_price)}
              %
            </p>
          )}
          {product.type === "variable" &&
            variations.idList.length > 0 &&
            variations.data[product.variations[0]].sale_price && (
              <p className="discount-sticker">
                -
                {getDiscountPercentage(
                  variations.data[product.variations[0]].sale_price,
                  variations.data[product.variations[0]].regular_price
                )}
                %
              </p>
            )}
          {/* {product.sale_price && <div className='black-friday-sticker'></div>} */}
          {/* {product.type === 'variable' && variations.idList.length > 0 && variations.data[product.variations[0]].sale_price && <div className='black-friday-sticker'></div>} */}
        </div>
        <h3>
          {lang === "it"
            ? getCorrectMetaData(product, products, "name_it")
            : product.name}
        </h3>
        <p>
          {lang === "it"
            ? getCorrectMetaData(product, products, "short_description_it")
            : getCorrectMetaData(product, products, "short_description")}
        </p>
        <div className="price">
          {product.type === "simple" && product.sale_price && (
            <span>{product.regular_price}€</span>
          )}
          {product.type === "simple" && <p>{product.price} €</p>}
          {product.type === "variable" &&
            variations.idList.length > 0 &&
            variations.data[product.variations[0]].sale_price && (
              <span>
                {variations.data[product.variations[0]].regular_price} €
              </span>
            )}
          {product.type === "variable" && variations.idList.length > 0 && (
            <p>{variations.data[product.variations[0]].price} €</p>
          )}
        </div>
        <button>{lang === "it" ? "SCOPRILO" : "SHOP NOW"}</button>
      </Link>
    </StyledProductCard>
  );
};

function mapStateToProps(state) {
  return {
    products: state.products,
    media: state.media,
    tags: state.tags,
    variations: state.variations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductCard)
);
