import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import styled from 'styled-components'
import uuid from 'uuid/v4'

import arrow from '../../assets/arrow.svg'

import { cleanSlug } from '../../helpers/cleanCategories'

const StyledSingle = styled.li`
    position: relative;
    div {
        p{
            font-size: 1.4rem;
        }
    }
    img {
        height: 12px;
        padding-left: 5px;
    }
    .subList {
        display: 'flex';
        visibility: ${props => props.isShown ? 'visible' : 'hidden'};
        position: absolute;
        flex-direction: column;
        background-color: white;
        box-shadow: 0 2px 5px rgba(0,0,0,.1);
        width: 250px;
        z-index: 5;
        left: -78px;
        @media screen and (max-width: 1100px) {
            visibility: ${props => props.isClicked ? 'visible' : 'hidden'};
            height: ${props => props.isClicked ? '100%' : '0'};
            opacity: ${props => props.isClicked ? '1' : '0'};
            position: relative;
            top: 10px;
            left: 0;
            box-shadow: none;

        } 
        li {
            margin: 20px 45px;
            @media screen and (max-width: 1100px) {
               margin: 0;
            }
        }
    }
`

const SingleCategory = props => {
    const { category, subCategories, language, toogleMenu, screen } = props;

    const [isShown, setIsShown] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -115; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return (
        <StyledSingle isShown={isShown} isClicked={isClicked}>

            {screen === 'desktop' &&
                <NavLink
                    activeStyle={{ textDecoration: 'underline' }}
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                    onClick={() => setIsClicked(!isClicked)}
                    to={`/${language}/${cleanSlug(category.slug)}`}>
                    {category.navbarmenu_title.toUpperCase()}
                    <img src={arrow} alt="" />
                </NavLink>}
            {screen === 'mobile' &&
                <div onClick={() => setIsClicked(!isClicked)}>
                    <p>{category.navbarmenu_title.toUpperCase()}</p>
                    <div>+</div>
                </div>}
            <ul
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
                className='subList'>
                <li onClick={toogleMenu} key={uuid()}>
                    <HashLink smooth to={`/${language}/${cleanSlug(category.slug)}`}>
                        {language === 'it' ? 'TUTTI I PRODOTTI' : 'ALL PRODUCTS'}
                    </HashLink>
                </li>
                {subCategories.visibles.map(slug => {
                    if (subCategories.data[slug].parent_slug === category.slug) {
                        return (
                            <li onClick={toogleMenu} key={uuid()}>
                                <NavHashLink scroll={scrollWidthOffset} to={`/${language}/${cleanSlug(category.slug)}#${slug}`}>
                                    {subCategories.data[slug].navbarmenu_title}
                                </NavHashLink>
                            </li>
                        )
                    }
                    return null;
                })}
            </ul>
        </StyledSingle>
    )
}

function mapStateToProps(state) {
    return {
        subCategories: state.subCategories,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleCategory);
