import { combineReducers } from 'redux';

import * as types from '../../actions/actionTypes'

const idList = (state = [], action) => {
    switch (action.type) {
        case types.ADD_MEDIA:
            return [...state, action.payload.result].flat();
        default:
            return state;
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_MEDIA:
            return Object.assign(state, action.payload.entities.media);
        default:
            return state;
    }
}

const media = combineReducers({
    idList,
    data,
})

export default media;