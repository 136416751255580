import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components'
import uuid from 'uuid/v4';

import binIcon from '../../assets/bin-icon.png'

import { getCorrectMetaData } from '../../helpers/getCorrectMetaData'
import { removeProduct } from '../../helpers/cartHelpers'
import { addCart } from '../../redux/actions/actionCreators'

import Tag from './Tag';

const StyledProductCard = styled.article`
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e2e2e2;
    margin-top: 30px;
    padding-bottom: 20px;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        height: 210px;
    }
    .left-column {
        display: flex;
        .product-info {
        
            padding-left: 10px;
            h3 {
                font-size: 2.4rem;
                font-weight: bold;
                @media screen and (max-width: 1000px) {
                    font-size: 1.8rem;
                }
            };
            .tags-container {
                display: flex;
                flex-wrap: wrap;
            }
            .attributes {
                font-size: 1.4rem;
            }
            .remove-product {
                display: flex;
                position: absolute;
                bottom: 20px;
                cursor: pointer;
                @media screen and (max-width: 1000px) {
                    left: 25px;
                    bottom: 21px;
                }
                img {
                    height: 15px;
                    margin-right: 5px;
                }
                p {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .right-column {
        .select-container {
            position: absolute;
            right: 0;
            width: 92px;
            border: 1px solid black;
            @media screen and (max-width: 1000px) {
                bottom: 50px;
            }
            select {
                width: 90px;
                cursor: pointer;
                font-size: 1.4rem;
            }
        }
        .price {
            position: absolute;
            bottom: 20px;
            right: 0;
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 1000px) {
                bottom: 20px;
            }
            p {
                color: black;
                font-size: 1.4rem;
                font-weight: bolder;
                white-space: nowrap;
            }
            .discounted-price {
                color: red;
                text-decoration-line: line-through;
                margin-right: 10px;
                white-space: nowrap;
            }
        }
    }
`

const ProductCard = props => {
    const { cart, products, variations, product, media, match, tags, cartKey, addCart } = props;
    const [quantity, setQuantity] = useState(product.quantity)

    const selectQuantity = event => {
        const value = event.target.value
        const updatedCart = JSON.parse(JSON.stringify(cart))
        updatedCart[cartKey].quantity = value
        setQuantity(value)
        addCart(updatedCart)
        localStorage.setItem('cart', JSON.stringify(updatedCart))
    }

    return (
        <StyledProductCard >
            <div className='left-column'>
                {Object.keys(media.data).length > 0 &&
                    Object.keys(products.data).length > 0 &&
                    <Link to={match.params.language === 'it' ? `/it/${product.slug}` : `/en/${product.slug}`}>
                        <img src={media.data[products.data[product.slug].images[0]].media_details.sizes.thumbnail.source_url} alt="" />
                    </Link>
                }
                <div className='product-info'>
                    <h3>
                        {match.params.language === 'it' ?
                            getCorrectMetaData(products.data[product.slug], products, 'name_it') :
                            products.data[product.slug].name}
                    </h3>
                    {tags.slugList.length > 0 &&
                        <div className='tags-container'>
                            {products.data[product.slug].tags.map(slug =>
                                <Tag key={uuid()} tagSlug={slug} />)}
                        </div>}
                    <p className='attributes'>
                        {Object.keys(variations.data).length > 0 &&
                            variations.data[product.variation] &&
                            variations.data[product.variation].attributes.map(attribute => {
                                if (match.params.language === 'it' && attribute.name.endsWith('_it')) {
                                    return `${attribute.name.slice(0, -3)}: ${attribute.option}`
                                } else if (match.params.language !== 'it' && !attribute.name.endsWith('_it')) {
                                    return `${attribute.name}: ${attribute.option}`
                                }
                                return null;
                            })
                        }
                    </p>
                    <div className='remove-product' onClick={() => removeProduct(cartKey, cart, addCart)} >
                        <img src={binIcon} alt="" />
                        <p>{match.params.language === 'it' ? 'Elimina' : 'Remove'}</p>
                    </div>
                </div>
            </div>
            <div className='right-column'>
                <div className='select-container'>
                    <select onChange={e => selectQuantity(e)} value={quantity}>
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8, 9].map(option =>
                                <option key={uuid()}>{option}</option>
                            )
                        }
                    </select>
                </div>
                <div className='price'>
                    {products.data[product.slug].sale_price && <p className='discounted-price'>{(products.data[product.slug].regular_price * product.quantity).toFixed(2)} €</p>}
                    <p>{(products.data[product.slug].price * product.quantity).toFixed(2)} €</p>
                </div>
            </div>
        </StyledProductCard>
    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        products: state.products,
        variations: state.variations,
        media: state.media,
        tags: state.tags
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addCart
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductCard));