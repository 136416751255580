import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'
import { Helmet } from 'react-helmet';

import CartMain from '../components/cartPage/CartMain';
import CartEmpty from '../components/cartPage/CartEmpty';
import Loader from '../components/Loader'

const StyledCartPage = styled.section`

`

const CartPage = props => {
    const { match, cart, variations } = props;

    return (
        <StyledCartPage>
            <Helmet>
                <title>{match.params.language === 'it' ? 'Carrello - YogicYantra' : 'Cart - YogicYantra'}</title>
            </Helmet>
            {Object.keys(cart).length ?
                Object.keys(variations.data).length > 0 ?
                    <CartMain /> :
                    <Loader language={match.params.language} page='cart' />  :
                <CartEmpty language={match.params.language}/>
            }
        </StyledCartPage>
    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        variations: state.variations
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartPage));