import { combineReducers } from 'redux';

import * as types from '../../actions/actionTypes'

const slugList = (state = [], action) => {
    switch (action.type) {
        case types.ADD_SUB_CATEGORIES:
            return action.payload.result;
        default:
            return state;
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_SUB_CATEGORIES:
            return action.payload.entities.subCategories;
        default:
            return state;
    }
}

const visibles = (state = [], action) => {
    switch (action.type) {
        case types.SET_VISIBLE_SUB_CATEGORIES:
            return action.payload
        default:
            return state;
    }
}

const images = (state = {}, action) => {
    switch (action.type) {
        case types.ADD_SUB_CATEGORIES:
            return action.payload.entities.productImg;
        default:
            return state;
    }
}

const subCategories = combineReducers({
    slugList,
    visibles,
    data,
    images
})

export default subCategories;