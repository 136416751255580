import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
// import { Helmet } from 'react-helmet';

import MailerLite from '../components/MailerLite';

import leafletFace from '../assets/SieroVisoLiftingPlus.pdf';
import leafletBody from '../assets/LozioneCorpoNutriPlus.pdf';
import leafletShampoo from '../assets/ShampooEnergyPlus.pdf'

const StyledLeafletLanding = styled.section`
    .header{
        background-image: ${props => `url(${props.header})`};
        height: 550px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        text-align: center;
        h2{
            padding: 260px 15px 0 15px;
            font-size: 3.5rem;
            color: white;
            @media screen and (max-width: 1000px) {
                font-size: 3rem;
            }
        }
    }

    .container {
        max-width: 1280px;
        width: 86%;
        margin: 0 auto;
        padding: 50px 0;
        font-size: 1.8rem;
        .cards{
            display: flex;
            justify-content: space-between;
            padding: 50px 0;
            @media screen and (max-width: 950px) {
                flex-direction: column;
                align-items: center;
            }
            .card {
                position: relative;
                height: 300px;
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
                @media screen and (max-width: 1100px) {
                    height: 250px;
                }
                @media screen and (max-width: 950px) {
                    height: 350px;
                    width: 350px;
                    margin-top: 50px;
                }
                img{
                    position: absolute;
                    height: 100%;
                    z-index: -1
                }
                h3{
                    width: 300px;
                    padding-top: 173px;
                    font-size: 2.2rem;
                    font-weight: bold;
                    text-align: center;
                    @media screen and (max-width: 1100px) {
                        width: 250px;
                    }
                    @media screen and (max-width: 950px) {
                        width: 350px;
                        padding-top: 223px;
                    }
                }
            }
        }
    }
`

const LeafleatLandingPage = props => {
    const { leaflet, media } = props;
    if (leaflet.visibles.length > 0 ) {
        return (
            <StyledLeafletLanding header={media.data[leaflet.data[leaflet.visibles[0]].main_img.ID].guid.rendered} >
                <div className='header'>
                    <h2>{leaflet.data[leaflet.visibles[0]].description}</h2>
                </div>

                <div className='container'>
                    <div className='content' dangerouslySetInnerHTML={{ __html: leaflet.data[leaflet.visibles[0]].content_text}}></div>
                    <div className='cards'>
                        <a className='card' target="_blank" rel="noopener noreferrer" href={leafletFace} >
                            <img src={media.data[leaflet.data[leaflet.visibles[0]].leaflet_card_img_1.ID].guid.rendered} alt="" />
                            <h3>{leaflet.data[leaflet.visibles[0]].leaflet_card_text_1}</h3>
                        </a>
                        
                        <a className='card' target="_blank" rel="noopener noreferrer" href={leafletBody} >
                            <img src={media.data[leaflet.data[leaflet.visibles[0]].leaflet_card_img_2.ID].guid.rendered} alt="" />
                            <h3>{leaflet.data[leaflet.visibles[0]].leaflet_card_text_2}</h3>
                        </a>
                        
                        <a className='card' target="_blank" rel="noopener noreferrer" href={leafletShampoo} >
                            <img src={media.data[leaflet.data[leaflet.visibles[0]].leaflet_card_img_3.ID].guid.rendered} alt="" />
                            <h3>{leaflet.data[leaflet.visibles[0]].leaflet_card_text_3}</h3>
                        </a>
                    </div>
                </div>
                <MailerLite />
            </StyledLeafletLanding>
        )
    } return null;
}

function mapStateToProps(state) {
    return {
        leaflet: state.leaflet,
        media: state.media,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeafleatLandingPage)) ;