import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { addCart } from '../../redux/actions/actionCreators'
import { getCorrectMetaData } from '../../helpers/getCorrectMetaData'


const StyledBuyNowModal = styled.div`
    position: sticky;
    bottom: 0;
    display: flex;
    visibility: ${props => !props.isTop ? 'visible' : 'hidden'};
    opacity: ${props => !props.isTop ? '1' : '0'};
    transition: 0.5s;      
    justify-content: space-between;
    background-color: white;
    border: 1px solid;
    border-bottom: none;
    height: 70px;
    @media screen and (max-width: 1400px) {
        width: 118%;
        margin: 0 -10%; 
        border: none;
        border-top: 2px solid;
    }
    .left{
        display: flex;
        align-items: center;    
        font-size: 1.6rem;
        @media screen and (max-width: 700px) {
            font-size: 1.2rem;
            white-space: normal;
        }
        img{
            height: 50px;
            margin-left: 10%;
            margin-right: 20px;
            @media screen and (max-width: 700px) {
                margin-left: 2%;
                margin-right: 0;
            }
        }
        p{
            white-space: nowrap;
            @media screen and (max-width: 700px) {
                white-space: normal;
                padding-right: 50px;
            }
        }
        .variation{
            @media screen and (max-width: 700px) {
                display: none;
            }
        }
    }
    .right{
        display: flex;
        justify-content: end;
        align-items: center;
        margin-right: 20px;
        @media screen and (max-width: 650px) {
            margin-right: 15px;
        }
        p{
            font-size: 2rem;
            white-space: nowrap;
            font-weight: bold;
            margin-right: 20px;
            @media screen and (max-width: 650px) {
                font-size: 1.4rem;
                margin-right: 10px;
            }
        }
        .cta-product{
            font-size: 1.6rem;
            white-space: nowrap;
            margin-right: 10%;
            margin-bottom: 0;
            margin-top: 0;
            padding: 8px 30px;
            @media screen and (max-width: 650px) {
                font-size: 1.2rem;
                padding: 8px 15px;
            }
        }
    }
`

const BuyNowModal = props => {
    const { product, products, variations, media, match, buyNow, stockStatus } = props;

    const [isTop, setIsTop] = useState('false')

    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y > -600) {
            setIsTop(true)
        } else {
            setIsTop(false)
        }
    })

    if (stockStatus === 'instock' ) return (
        <StyledBuyNowModal isTop={isTop}>
            <div className='left'>
                <img src={media.data[product.images[0]].guid.rendered} alt={match.params.language === 'it' ? media.data[product.images[0]].alternative_text_it : media.data[product.images[0]].alt_text} />
                <p>{match.params.language === 'it' ? getCorrectMetaData(product, products, 'name_it') : product.name}</p>
                {Object.keys(variations.selectedVariation).length > 0 && <p className='variation'>- {match.params.language === 'it' ? variations.selectedVariation.attributes[1].option : variations.selectedVariation.attributes[0].option}</p>}
            </div>
            <div className='right'>
                <p>{product.price} €</p>
                <Link onClick={buyNow} to={`/${match.params.language}/cart`} className='cta-product'>{match.params.language === 'it' ? 'COMPRA ORA' : 'BUY NOW'}</Link> 
            </div>
        </StyledBuyNowModal>
    )
    return null
}

function mapStateToProps(state) {
    return {
        variations: state.variations,
        products: state.products,
        media: state.media,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addCart,
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyNowModal));