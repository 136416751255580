import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import { withRouter } from "react-router";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const StyledGallery = styled.div`
    width: 49.5%;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
    .tags-container {
        display: flex;
        justify-content: center;
    }
    .img-container {
        position: relative;
    
        .out-of-stock{
            position: absolute;
            height: 100px;
            width: auto;
            bottom: 88px;
            right: 0;
            z-index: 1;
        }
        .black-friday-sticker{
            position: relative;
            background-image: url('https://payments.yogicyantra.com/wp-content/uploads/2021/11/Black-Friday-label-2.png');
            height: 40px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .carousel-status {
        display: none;
    }
    .control-next{
        color: black;
        ::before{
            border-left: 8px solid black !important;        
        }
    }
    .control-prev{
        color: black;
        ::before{
            border-right: 8px solid black !important;        
        }
    }
    .dot{
        background: black !important;
    }
`

const StyledTags = styled.p`
    border: ${props => `1px solid ${props.color}`};
    color: ${props => props.color};
    font-size: .9rem !important;
    padding: 4px 10px 2px !important;
    margin: 5px 5px;
    border-radius: 26px;
    white-space: nowrap;
    letter-spacing: 1.1px;
    @media screen and (max-width: 1000px) {
        margin-top: 10px;
        .carousel .thumbs-wrapper{
            display: none;
        }
    }
    @media screen and (max-width: 650px) {
        margin: 3px 5px 0;
        font-size : .7rem !important;
    }
`

const Gallery = props => {
    const { product, products, media, match, tags, variations } = props;
    const [stockStatus, setStockStatus] = useState('')

    useEffect(() => {
        if (Object.keys(variations.selectedVariation).length) {
            setStockStatus(variations.selectedVariation.stock_status)
        } else {
            setStockStatus(product.stock_status)
        }
    }, [variations.selectedVariation, product.stock_status])

    return (
        <StyledGallery >
            {tags.slugList.length > 0 && <div className='tags-container'>{products.data[product.slug].tags.map(slug => <StyledTags key={uuid()} color={tags.data[slug].colore}>{match.params.language === 'it' ? tags.data[slug].name_it : tags.data[slug].name}</StyledTags>)}</div>}
            <div className='img-container'>
                {stockStatus === "outofstock" && match.params.language === 'en' && <img className='out-of-stock' src='https://payments.yogicyantra.com/wp-content/uploads/2021/01/BLURP-PRODOTTO-OUT-OF-STOCK.png' alt='Out Of Stock' />}
                {stockStatus === "outofstock" && match.params.language === 'it' && <img className='out-of-stock' src='https://payments.yogicyantra.com/wp-content/uploads/2021/01/BLURP-PRODOTTO-ESAURITO.png' alt='Prodotto Esaurito' />}
                {/* {product.sale_price && <div className='black-friday-sticker'></div>} */}
                {/* {product.type === 'variable' && variations.idList.length > 0 && variations.data[product.variations[0]].sale_price && <div className='black-friday-sticker'></div>} */}
                <Carousel renderThumbs={() => null} emulateTouch={true} >
                    {Object.keys(media.data).length > 0 && product.images.map(id =>
                        <img key={uuid()} src={products.images[id].src} alt={match.params.language === 'it' ? media.data[id].alternative_text_it : media.data[id].alt_text} />
                    )}
                </Carousel>
            </div>
        </StyledGallery>
    )
}

function mapStateToProps(state) {
    return {
        products: state.products,
        media: state.media,
        tags: state.tags,
        variations: state.variations
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Gallery));
