import React, { useState } from 'react';
import styled from 'styled-components';
import plusIcon from '../../assets/plus-icon.png';
import minusIcon from '../../assets/minus-icon.png';

const StyledQAndA = styled.div`
    border: 1px solid #d9d9d9;
    padding: 20px;
    margin-bottom: 30px;
    background-color: #f4f4f4;
    cursor: pointer;
    @media screen and (max-width: 650px) {
        padding: 13px;
    }
    .question{
        display: flex;
        justify-content: space-between; 
        img{
            height: 30px;
        }
    }
    h4 {
        padding-top: 10px;
        font-size: 1.8rem;
        color: ${props => props.isShown ? 'black' : '#666'};
        font-weight: ${props => props.isShown ? 'bold' : 'inherit'};
        @media screen and (max-width: 650px) {
            font-size: 1.4rem;
        }
    }
    .toogle {
        background-image: ${props => props.icon };
        border-radius: 50%;
        background-color: #cecbcb;
        padding: 3px 5px;
        font-size: 1.6rem;
        color: white;
        
    }
    .answer {
        height: ${props => props.isShown ? '100%' : '0'};
        visibility: ${props => props.isShown ? 'visible' : 'hidden'};
        overflow: hidden;
        opacity: ${props => props.isShown ? '1' : '0'};
        width: 100%;
        background-color: #f4f4f4;
        font-size: 1.6rem;
        line-height: 1.5;
        transition: height 0s, padding-top .5s, visibility 1s, opacity .5s;
        padding-top: ${props => props.isShown ? '20px' : '-50px'};
        li {
            list-style-type: disc;
            margin-left: 10px;
        }
    }
`

const QAndA = props => {
    const { qAndA } = props;
    const [isShown, setIsShown] = useState(false);
    if (qAndA[0]) {
        return (
            <StyledQAndA onClick={() => setIsShown(!isShown)} isShown={isShown} >
                <div className='question'>
                    <h4>{qAndA[0]}</h4>
                    <img src={isShown ? minusIcon : plusIcon } alt=""/>
                </div>
                <p className='answer' dangerouslySetInnerHTML={{ __html: qAndA[1] }} />
            </StyledQAndA>
        )
    }
    return null;
}

export default QAndA;