import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";

const StyledTrusted = styled.div`
    border-bottom: 1px solid #e2e2e2;
    padding: 50px 0 20px 0;
    h2 {
        text-align: center;
        font-size: 3.6rem;
        padding-bottom: 20px;
        @media screen and (max-width: 650px){
            font-size: 2.6rem;    
            font-weight: bold;
        }
    }
    .flex-container{
        display: flex;
        justify-content: space-evenly;
        width: 80%;
        margin: 0 auto;
        flex-wrap: wrap;
        .img-container{
            width: 25%;
            padding: 30px 0;
            @media screen and (max-width: 1000px) {
                width: 50%;
            }
            img {
                display: block;
                margin: 0 auto;
                height: 150px;
                @media screen and (max-width: 800px) {
                padding-top: 15px;
                height: 100px;
                }
            }
        }
    }
`

const TrustedBy = props => {
    const { language, homePage, media } = props;
    return (
        <StyledTrusted>
            <h2>{language === 'it' ? 'Parlano di Noi' : 'Trusted By'}</h2>
            {
                Object.keys(homePage).length > 0 &&
                media.data[homePage.trusted_by_img_1.ID] &&
                <div className='flex-container'>
                    <div className='img-container'>
                        <img src={homePage.trusted_by_img_1.guid} alt={language === 'it' ? media.data[homePage.trusted_by_img_1.ID].alternative_text_it : media.data[homePage.trusted_by_img_1.ID].alt_text} />
                    </div>
                    <div className='img-container'>
                        <img src={homePage.trusted_by_img_2.guid} alt={language === 'it' ? media.data[homePage.trusted_by_img_2.ID].alternative_text_it : media.data[homePage.trusted_by_img_2.ID].alt_text} />
                    </div>
                    <div className='img-container'>
                        <img src={homePage.trusted_by_img_3.guid} alt={language === 'it' ? media.data[homePage.trusted_by_img_3.ID].alternative_text_it : media.data[homePage.trusted_by_img_3.ID].alt_text} />
                    </div>
                    <div className='img-container'>
                        <img src={homePage.trusted_by_img_4.guid} alt={language === 'it' ? media.data[homePage.trusted_by_img_4.ID].alternative_text_it : media.data[homePage.trusted_by_img_4.ID].alt_text} />
                    </div>
                    <div className='img-container'>
                        <img src={homePage.trusted_by_img_5.guid} alt={language === 'it' ? media.data[homePage.trusted_by_img_5.ID].alternative_text_it : media.data[homePage.trusted_by_img_5.ID].alt_text} />
                    </div>
                    <div className='img-container'>
                        <img src={homePage.trusted_by_img_6.guid} alt={language === 'it' ? media.data[homePage.trusted_by_img_6.ID].alternative_text_it : media.data[homePage.trusted_by_img_6.ID].alt_text} />
                    </div>
                    <div className='img-container'>
                        <img src={homePage.trusted_by_img_7.guid} alt={language === 'it' ? media.data[homePage.trusted_by_img_7.ID].alternative_text_it : media.data[homePage.trusted_by_img_7.ID].alt_text} />
                    </div>
                    <div className='img-container'>
                        <img src={homePage.trusted_by_img_8.guid} alt={language === 'it' ? media.data[homePage.trusted_by_img_8.ID].alternative_text_it : media.data[homePage.trusted_by_img_8.ID].alt_text} />
                    </div>
                </div>
            }
        </StyledTrusted>
    )
}

function mapStateToProps(state) {
    return {
        homePage: state.general.homePage,
        media: state.media
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrustedBy));
