import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const StyledFreeShipping = styled.div`
    display: ${props => props.isShown ? 'block' : 'none'};
    background-color: #00b67a;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid black;
    @media screen and (max-width: 1000px) {
        z-index: 3;
    }
    @media screen and (max-width: 650px) {
        height: 20px;
    }
    p {
        text-align: center;
        color: white;
        font-size: 1.5rem;
        padding: 9px 30px 0 30px;
        font-weight: bold;
        @media screen and (max-width: 650px) {
            padding-top: 6px;
            font-size: 1.1rem;
        }
    }

    button {
        float: right;
        margin-left: -50%;
        margin-top: 3px;
        background: none;
        border: none;
        font-size: 2rem;
        cursor: pointer;
        @media screen and (max-width: 650px) {
            font-size: 1.1rem;
        }
    }
`

const FreeShipping = props => {
    const { general } = props;
    const [isShown, setIsShown] = useState(true)

    if (general.data[general.visibles]) {
        return (
            <StyledFreeShipping isShown={isShown}>
                <button onClick={() => setIsShown(false)}>X</button>
                <p>{general.data[general.visibles].shipping_component}</p>
            </StyledFreeShipping>
        )
    } return null
}

function mapStateToProps(state) {
    return {
        general: state.general
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeShipping);