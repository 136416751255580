import React from 'react';
import styled from 'styled-components'


const StyledDeliveryWithin = styled.div`
    background-color: white;
    border-bottom: 4px solid #cdd2d2;
    margin: 0 auto;
    width: 100%;
    padding: 20px;
    h3 {
        font-size: 2.1rem;
        font-weight: bold;
        padding-bottom: 30px;
    }
    .icons {
        display: flex;
        flex-wrap: wrap;
        div {
            width: 25%;
            img {
                display: block;
                margin: auto;
                height: 40px;
                @media screen and (max-width: 400px) {
                    height: 25px;
                }
            }
        }
    }
`

const DeliveryWithinTab = props => {
    const { language } = props;    

    return (
        <StyledDeliveryWithin>
            <h3>{language === 'it' ? 'Consegna entro 5 giorni lavorativi' : 'Delivery within 5 working days'}</h3>
            <div className='icons'>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Ups.png" alt="" />
                </div>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Fed-Ex.png" alt="" />
                </div>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/SDA.png" alt="" />
                </div>
                <div>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/TNT.png" alt="" />
                </div>
            </div>
        </StyledDeliveryWithin>
    )
}

export default DeliveryWithinTab;