import React, { useEffect } from 'react';

const RecensioniVerificate = props => {
  const { products, type } = props;

  useEffect(() => {
    if (window.nrIncludeCss) {
      window.nrIncludeCss();
      window.nrSearchForStars();
      window.nrSearchForReviews();
    };
  });

  return (
    <div className={type} data-product-id={products} />
  )
}

export default RecensioniVerificate;