import React, { useEffect } from 'react'
import styled from 'styled-components';

const StyledMailerLite = styled.section`
    display: flex;
    justify-content: space-between;
    border: 4px solid black;
    align-items: center;
    margin: 20px 0;
    @media screen and (max-width: 1000px) {
        flex-direction: column-reverse;
    }
    img{
        width: 400px;
        @media screen and (max-width: 1000px) {
            width: 100%;
        }
    }
    .mailerlite{
        padding-left: 40px;
        padding-right: 20px;
        @media screen and (max-width: 1000px) {
            padding: 0;
        }
    }
`

const MailerLiteYoni = props => {
    useEffect(() => {
        const js = `
        (function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
            var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
            f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
            var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
            _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');
            
            var ml_account = ml('accounts', '2818420', 'q7g2e4d0m8', 'load');
      `;
        const script = document.createElement("script");
        const scriptText = document.createTextNode(js);
        script.appendChild(scriptText);
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "https://static.mailerlite.com/js/w/webforms.min.js?CODE";
        document.body.appendChild(script2);
    }, [])
    return (
        <StyledMailerLite image='https://payments.yogicyantra.com/wp-content/uploads/2020/01/combo-shakti-shiva.jpg'>
            <div className="ml-form-embed mailerlite"
                data-account="2818420:q7g2e4d0m8"
                data-form="4228906:r4l8u5">
            </div>
            <img src='https://payments.yogicyantra.com/wp-content/uploads/2021/06/AYD2573.jpg' alt='' /> 
        </StyledMailerLite>
    )
}

export default MailerLiteYoni;