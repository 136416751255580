import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { cleanSlug, cleanTitle } from '../../helpers/cleanCategories'

const StyledBreadcamp = styled.div`
    padding-top: 30px;
    font-size: 1.6rem;
    @media screen and (max-width: 1000px) {
            display: none;
        }
    .home {
    color: black;
    } 
    a{
        color: ${props => props.product ? 'black' : 'rgb(63, 63, 63)'};
    }
    p {
        display: inline-block;
        color: rgb(63, 63, 63);
    }
`

const Breadcamp = props => {
    const { category, product } = props;

    return (
        <StyledBreadcamp product={product}>
            <Link className='home' to='/'>Home</Link>
            <span>>></span>
            <Link to={cleanSlug(category.slug)}>{cleanTitle(category.title.rendered)}</Link>
            {product &&
            <>
            <span>>></span>
            <p>{product.name}</p>
            </>
            }
            
        </StyledBreadcamp>
    )
}

export default Breadcamp;