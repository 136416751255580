import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { NavHashLink } from 'react-router-hash-link';
import { withRouter } from "react-router";
import uuid from 'uuid/v4';

import { cleanSlug } from '../../helpers/cleanCategories'

const StyledTopContent = styled.section`
    padding-top: 50px;
    text-align: center;
    border-bottom: 1px solid #e2e2e2;
    h1{
        font-size: 3.6rem;
        padding-bottom: 30px;
        @media screen and (max-width: 650px){
            font-size: 2.4rem;    
        }
    }
    p {
        font-size: 1.8rem;
        padding-bottom: 60px;
        @media screen and (max-width: 650px){
            font-size: 1.6rem;   
        }    
    }
    .blurp-container {
        display: flex;
        justify-content: space-around;
        padding-bottom: 30px;
        flex-wrap: wrap;
        @media screen and (max-width: 650px){
                flex-direction: column;
                align-items: center;
        }
    }
`

const StyledBlurp = styled.div`
    width: 26%;
    height: 300px;
    background-image: url(${props => props.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid black;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
    @media screen and (max-width: 1400px){
        height: 280px;
    }
    @media screen and (max-width: 1200px){
        height: 250px;
    }
    @media screen and (max-width: 1000px){
        width: 45%;
        height: 350px;
    }
    @media screen and (max-width: 650px){
        height: 250px;
        width: 95%;
        margin: 10px 0;
    }
    a {
        color: white;
        font-size: 2.1rem;
        border: 2px solid white;
        background-color: rgba(91,91,91,0.36);
        border-radius: 8px;
        box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.3);
        padding: 10px 5%;
    }
`

const TopContent = props => {
    const { shopNow, language, categories } = props; 

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = window.innerWidth > 1000 ? 0 : -115;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return (
        <StyledTopContent >
            <h1>{shopNow.description}</h1>
            <p>{shopNow.sub_description}</p>
            <div className='blurp-container'>
                {categories.visibles.length > 0 && categories.visibles.map(slug => {
                    const category = categories.data[slug]
                    return (
                        <StyledBlurp key={uuid()} category={category} image={category.blurp_image_shop.guid} >
                            <NavHashLink scroll={scrollWidthOffset} to={`/${language}/${cleanSlug(category.slug)}#${category.slug}`}>{category.navbarmenu_title.toUpperCase()}</NavHashLink>
                        </StyledBlurp>
                    )
                })}
            </div>
        </StyledTopContent>
    )
}

function mapStateToProps(state) {
    return {
        shopNow: state.general.shopNow,
        categories: state.categories
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopContent));