import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import dotenv from 'dotenv';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import TagManager from 'react-gtm-module';


import rootReducer from './redux/reducers/rootReducer'
import GlobalStyle from './GlobalStyle'

dotenv.config()

const composeEnhancers =
    (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

const tagManagerArgs = {
    gtmId: 'GTM-W43CH8T',
    events: {
        productPage: 'productPage',
        addToCart: 'addToCart'
    }
}

TagManager.initialize(tagManagerArgs)

const target = document.getElementById('root');
if (target) {
    ReactDOM.render(
        <Provider store={store}>
            <Router>
                <GlobalStyle />
                <App />
            </Router>
        </Provider>
        , target)
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
