import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import itaFlag from "../assets/italy.svg";
import ukFlag from "../assets/united-kingdom.svg";
import arrow from "../assets/arrow-white.png";

import { changeAddProdModal } from "../redux/actions/actionCreators";

import TrustBox from "./TrustBox";
import SwitchLang from "./SwitchLang";

const StyledFooter = styled.footer`
  background-color: black;
  h4 {
    color: white;
    font-weight: bold;
    font-size: 1.6rem;
  }
  p {
    color: white;
    font-size: 1.3rem;
  }
  .top-line {
    display: flex;
    border-bottom: 2px solid white;
    flex-wrap: wrap;
    .card {
      width: 25%;
      height: 185px;
      border-right: 1px solid white;
      padding-top: 30px;
      padding-left: 2%;
      @media screen and (max-width: 1000px) {
        width: 50%;
        border-bottom: 1px solid white;
      }
      @media screen and (max-width: 550px) {
        width: 100%;
        padding-left: 10%;
      }
      h4 {
        padding-bottom: 28px;
      }
      a {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        color: white;
        font-size: 1.3rem;
        img {
          padding-right: 20px;
          height: 25px;
        }
      }
    }
    .language {
      position: relative;
      @media screen and (max-width: 550px) {
        height: 150px;
      }
      .select {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 60%;
        height: 40px;
        border: 2px solid white;
        cursor: pointer;
        .flag {
          height: 25px;
        }
        .arrow {
          height: 12px;
        }
      }
    }
    .info {
      div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        a {
          display: block;
          width: 45%;
        }
      }
    }
  }
  .middle-line {
    display: flex;
    flex-wrap: wrap;
    .card {
      height: 158px;
      width: 50%;
      border-bottom: 2px solid white;
      border-right: 1px solid white;
      @media screen and (max-width: 550px) {
        width: 100%;
        padding-left: 10%;
      }
      h4 {
        padding-top: 20px;
        padding-bottom: 12px;
        text-align: center;
        @media screen and (max-width: 550px) {
          text-align: left;
        }
      }
    }
    .trustbox {
      .trustpilot-widget {
        margin: 20px auto;
        @media screen and (max-width: 550px) {
          margin: 25px auto;
        }
      }
    }
    .payments {
      .icons {
        display: flex;
        flex-wrap: wrap;
        width: 250px;
        margin: 0 auto;
        padding-top: 20px;
        @media screen and (max-width: 550px) {
          margin: 0;
        }
        div {
          width: 25%;
          padding-bottom: 10px;
          img {
            background-color: white;
            display: block;
            margin: auto;
            height: 25px;
          }
        }
      }
    }
  }
  .bottom-line {
    padding: 20px 15px;
    p {
      text-align: center;
      font-size: 1.1rem;
    }
  }
`;

const Footer = (props) => {
  const { language } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/AWS/PRODUCT_API/tag.min.js";
    script.async = true;
    script.charset = "utf-8";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <StyledFooter>
      <div className="top-line">
        <div className="social card">
          <h4>{language === "it" ? "SEGUICI" : "FOLLOW US"}</h4>
          <div>
            <a
              href="https://www.facebook.com/yogicyantra"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/FB-logo.png"
                alt="Facebook Logo"
              />
              <p>/YogicYantra</p>
            </a>
            <a
              href="https://www.instagram.com/yogicyantra/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/IG-logo.png"
                alt="Instagram Logo"
              />
              <p>@YogicYantra</p>
            </a>
          </div>
        </div>
        <div className="contacts card">
          <h4>{language === "it" ? "PARLA CON NOI" : "TALK WITH US"}</h4>
          <div>
            <a
              href="https://wa.me/393792673603"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Whatsapp-logo.png"
                alt="Whatsapp Logo"
              />
              <p>+39 379 26 73 603</p>
            </a>
            <a
              href="mailto:info@yogicyantra.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Mail-logo.png"
                alt="mail Logo"
              />
              <p>info@yogicyantra.com</p>
            </a>
          </div>
        </div>
        <div className="language card">
          <h4>{language === "it" ? "SCEGLI LA LINGUA" : "CHANGE LANGUAGE"}</h4>
          <div className="select" onClick={() => setIsOpen(!isOpen)}>
            <img
              className="flag"
              src={language === "it" ? itaFlag : ukFlag}
              alt=""
            />
            <p>{language === "it" ? "Italiano" : "English"}</p>
            <img className="arrow " src={arrow} alt="" />
          </div>
          <SwitchLang isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <div className="info card">
          <h4>{language === "it" ? "SCOPRI" : "DISCOVER"}</h4>
          <div>
            <Link to={`/${language}/faq`}>
              {language === "it" ? "Domande Frequenti" : "FAQ"}
            </Link>
            <Link to={`/${language}/privacy`}>Privacy</Link>
            <Link to={`/${language}/referral`}>
              {language === "it"
                ? "Diventa un Ambassador"
                : "Become an Ambassador"}
            </Link>
            <a
              href={
                language === "it"
                  ? "https://blog.yogicyantra.com/blog/contatti/"
                  : "https://blog.yogicyantra.com/blog/en/contact/"
              }
            >
              {language === "it" ? "Contattaci" : "Contact Us"}
            </a>
            <a
              href={
                language === "it"
                  ? "https://payments.yogicyantra.com/checkout/refund_returns/"
                  : "https://payments.yogicyantra.com/checkout/en/refund_returns/"
              }
            >
              {language === "it" ? "Politica Resi" : "Refund Policy"}
            </a>
          </div>
        </div>
      </div>
      <div className="middle-line">
        <div className="trustbox card">
          <h4>{language === "it" ? "TI PUOI FIDARE" : "YOU CAN TRUST US"}</h4>
          <div className="widget">
            {language === "it" ? (
              <TrustBox
                attributes={{
                  locale: "it-IT",
                  template: "5419b6a8b0d04a076446a9ad",
                  height: "150px",
                  width: "100%",
                  theme: "dark",
                  tags: "eng",
                  stars: "4,5",
                }}
              />
            ) : (
              <TrustBox
                attributes={{
                  locale: "en-GB",
                  template: "5419b6a8b0d04a076446a9ad",
                  height: "150px",
                  width: "100%",
                  theme: "dark",
                  tags: "eng",
                  stars: "4,5",
                }}
              />
            )}
          </div>
        </div>
        <div className="payments card">
          <h4>{language === "it" ? "PAGAMENTI SICURI" : "SECURE PAYMENTS"}</h4>
          <div className="icons">
            <div>
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Paypal.png"
                alt=""
              />
            </div>
            <div>
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Visa.png"
                alt=""
              />
            </div>
            <div>
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Mastercard.png"
                alt=""
              />
            </div>
            <div>
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/American-Express.png"
                alt=""
              />
            </div>
            <div>
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Maestro.png"
                alt=""
              />
            </div>
            <div>
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Visa-Electron.png"
                alt=""
              />
            </div>
            <div>
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Postepay.png"
                alt=""
              />
            </div>
            <div>
              <img
                src="https://payments.yogicyantra.com/wp-content/uploads/2021/03/Visa-Debit.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-line">
        <p className="copywrite">
          © Copyright 2021 YogicYantra ·{" "}
          {language === "it"
            ? "Tutti i diritti sono riservati"
            : "All rights reserved"}{" "}
          · P.I. nr. 02362990513 · YogicYantra, Via di Secchieta 1,
          Montemignaio, Italy.
        </p>
      </div>
    </StyledFooter>
  );
};

function mapStateToProps(state) {
  return {
    addProdModal: state.addProdModal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeAddProdModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
