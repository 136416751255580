import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet'

import ProductsCategories from '../components/categoryPage/ProductsCategories'

const StyledContainer = styled.div`
        max-width: 1280px;
        width: 86%;
        margin: 0 auto;
        transition: 1s;
`

const CategoryLandingAds = props => {
    const { category, subCategories } = props;
    const findFirstSubCat = () => {
        if (subCategories.visibles.length > 0) {
            const slugSubCat = subCategories.visibles.find(slug => subCategories.data[slug].parent_slug === category.slug)
            return [subCategories.data[slugSubCat]]
        }
        return null
    }
    const findOtherSubCat = () => {
        if (subCategories.visibles.length > 0) {
            const slugSubCat = subCategories.visibles.find(slug => subCategories.data[slug].parent_slug === category.slug)
            const slugArr = subCategories.visibles.filter(slug => slug !== slugSubCat)
            return slugArr.map(slug => {
                if (subCategories.data[slug].parent_slug === category.slug) {
                    return subCategories.data[slug]
                }
                return null;
            }).filter(obj => obj)
        }
        return null
    }

    return (
        <>
            <Helmet>
                <title>{category.meta_title}</title>
                <meta name="description" content={category.meta_description} />
            </Helmet>
            <StyledContainer>  
                <ProductsCategories subCategories={findFirstSubCat()} />            
                <ProductsCategories subCategories={findOtherSubCat()} />
            </StyledContainer>
        </>
    )
}

function mapStateToProps(state) {
    return {
        subCategories: state.subCategories,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryLandingAds);