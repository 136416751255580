import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

const StyledHeader = styled.section`
    background-image: url(${props => props.image});
    height: 550px;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    @media screen and (max-width: 1000px) {
        padding-top: 110px;
    }
    h1 {
        font-size: 5rem;
        padding: 115px 15px 0 15px;
        /* color: white; black friday */
        /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; black friday */
        @media screen and (max-width: 1000px) {
        padding-top:0;
        font-size: 3rem;
        }
    }
    h2 {
        font-size: 2.4rem;
        padding: 10px 15px 0 15px;
        /* color: white; black friday */
        /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; black friday */
        @media screen and (max-width: 1000px) {
            font-size: 1.6rem;
        }
    }
    a {
        display: inline-block;
        font-size: 2.4rem;
        background-color: white;
        margin-top: 60px;
        padding: 13px;
        border: 2px solid black;
        border-radius: 8px;
        box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
        color: #0c0c0c;
        :hover{
            background-color: rgba(0,0,0,0.08);
        }
        @media screen and (max-width: 1000px) {
            font-size: 1.6rem;
        }
    }
`

const Header = props => {
    const { homePage, language } = props;
    return (
        <>
            {
                Object.keys(homePage).length > 0 &&
                <StyledHeader image={homePage.main_img.guid}>
                    <h1>{homePage.description}</h1>
                    <h2>{homePage.sub_description}</h2>
                    <Link to={`/${language}/shop_now`}>{language === 'it' ? 'Aquista Ora' : 'Shop Now'}</Link>
                </StyledHeader>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        homePage: state.general.homePage,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
