import { evaluate } from 'mathjs';

export const getProductsNumber = cart => {
    const values = Object.values(cart)
    return values.map(item => parseInt(item.quantity))
        .reduce((acc, curr) => acc + curr)
}

export const getTotalPrice = (cart, products) => {
    const values = Object.values(cart)
    return values.map(item => parseFloat(products.data[item.slug].price * item.quantity))
        .reduce((acc, curr) => acc + curr)
}

export const removeProduct = (key, cart, addCartCb) => {
    localStorage.removeItem('cart')
    const updatedCart = JSON.parse(JSON.stringify(cart));
    delete updatedCart[key]
    if (Object.keys(updatedCart).length) {
        addCartCb(updatedCart)
        localStorage.setItem('cart', JSON.stringify(updatedCart))
    } else {
        addCartCb({})
    }
}

export const getFlatRate = flatRateShipping => {
    return evaluate(flatRateShipping.settings.cost.value);
}

export const createQueryParam = (cart, products, appliedCoupon) => {
    let addToCart = '?clear-cart&add-to-cart='
    Object.values(cart).forEach(item => {
        if (item.variation) {
            addToCart += item.variation + ":" + item.quantity + ','
        } else {
            addToCart += products.data[item.slug].id + ":" + item.quantity + ','
        }
    })
    if (Object.keys(appliedCoupon).length) {
        addToCart += '&coupon_code=' + appliedCoupon.code
    }
    return addToCart;
}
