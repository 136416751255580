import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledHelpMe = styled.aside`
    position: sticky;
    top: 30px;
    left: 0;
    z-index: 1;
    @media screen and (max-width: 1000px) {
        top: 110px;
    }
    @media screen and (max-width: 650px) {
        top: 59px;
    }
    .inner-container{
        position: absolute;
        height: 55px;
        top: 0;
        transition: 0.3s;
        left:0px;
        background-color: rgb(202 247 233 / 79%);
        color: #6f6f6f;
        font-size: 1.3rem;
        width: 230px;
        padding: 15px;
        font-size: 1.3rem;
        border-radius: 3px 0 0 3px;
        @media screen and (max-width: 1000px) {
            top: 0;
            right: 0;
            width: 150px;
        }
        @media screen and (max-width: 650px) {
            height: 41px;
            width: 120px;
            padding: 10px;
            font-size: 1.1rem;
        }
        a{
            display: flex;
            align-items: center;
            justify-content: space-around;
            img {
                height: 25px;
                @media screen and (max-width: 650px) {
                    height: 20px;
                }
            }
        }
    }
`

const HelpMeModal = props => {
    const { language } = props;

    return (
        <StyledHelpMe>
            <div className='inner-container'>
                <Link to={`/${language}/assistance`} >
                    <p>{language === 'it' ? 'Hai dubbi?' : 'Need help?'}</p>
                    <img src="https://payments.yogicyantra.com/wp-content/uploads/2021/06/kisspng_emoticon_monochrome_photography_smiley_black_and_w_chat.png" alt="" />
                </Link>

            </div>
        </StyledHelpMe>
    )
}

export default HelpMeModal;