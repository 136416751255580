import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'

import tagIcon from '../../assets/tag-icon.png'

import { setAppliedCoupon } from '../../redux/actions/actionCreators'

const StyledCoupons = styled.div`
    margin: 0 auto;
    width: 100%;
    padding: 10px 20px;
    .text {
        cursor: pointer;
        display: flex;
        font-size: 1.6rem;
        img {
            height: 15px;
            margin-right: 5px;
        }
        p {
            font-weight: bolder;
            color: #008a54;
            text-decoration: underline;
        }
    }
    .input-container {
        visibility: ${props => props.isShown ? 'visible' : 'hidden'};
        height: ${props => props.isShown ? '100%' : '0'};
        opacity: ${props => props.isShown ? '100%' : '0'};
        margin-top: ${props => props.isShown ? '10px' : '0'};
        transition: 0.3s;
        .input {
            display: flex;
            input {
                font-size: 1.6rem;
                @media screen and (max-width: 430px) {
                    font-size: 1rem;
                }
                ::placeholder {
                    font-size: 1.6rem;
                    @media screen and (max-width: 430px) {
                        font-size: 1rem;
                    }    
                }
                padding: 13px 10px;
                border: 1px solid #00af6b;
            }
            .button {
                font-size: 1.6rem;
                font-weight: bolder;
                padding: 18px 23px 0 23px;
                background: linear-gradient(#00af6b,#00af6be0);
                border: rgba(10,10,10,0);
                color: white;
                cursor: pointer;
                @media screen and (max-width: 430px) {
                    font-size: 1rem;
                }  
                :hover{
                    background: linear-gradient(#008a54, #008a54d9);
                }
            }
        }
        .message {
            color: ${props => props.isRed ? 'red' : 'green'};
            font-size: 1.4rem;
            padding-top: 10px;
            }
    }
`

const CouponsTab = props => {
    const { match, coupons, setAppliedCoupon } = props;
    const [couponValue, setCouponValue] = useState('')
    const [couponMessage, setCouponMessage] = useState(false)
    const [isCouponError, setIsCouponError ] = useState(true)
    const [isShown, setIsShown] = useState(false)

    const handleChange = event => {
        setCouponValue(event.target.value)
    }

    const applyCoupon = () => {
        const couponLowerCase = couponValue.toLowerCase()
        if (coupons.couponsList.includes(couponLowerCase)) {
            if (coupons.data[couponLowerCase].usage_limit && coupons.data[couponLowerCase].usage_count >= coupons.data[couponLowerCase].usage_limit) {
                setIsCouponError(true)
                match.params.language === 'it' ? setCouponMessage('Questo coupon ha esaurito gli usi disponibili') : setCouponMessage('This coupon has run out of uses')
            } else if (new Date(coupons.data[couponLowerCase].date_expires_gmt).getTime() && new Date().getTime() > new Date(coupons.data[couponLowerCase].date_expires_gmt).getTime()) {
                setIsCouponError(true)
                match.params.language === 'it' ? setCouponMessage('Questo coupon è scaduto') : setCouponMessage('This coupon has expired')
            } else {
                setIsCouponError(false)
                setAppliedCoupon(coupons.data[couponLowerCase])
                match.params.language === 'it' ? setCouponMessage('Codice sconto applicato con successo') : setCouponMessage("Coupon code applied succesfully")
            }
        } else {
            setIsCouponError(true)
            match.params.language === 'it' ? setCouponMessage('Questo coupon non esiste') : setCouponMessage("This coupon doesn't exist")
        }
    }

    return (
        <StyledCoupons isShown={isShown} isRed={isCouponError}>
            <div className='text' onClick={() => setIsShown(!isShown)}>
                <img src={tagIcon} alt="" />
                <p>{match.params.language === 'it' ? 'Applica un codice sconto (facoltativo)' : 'Apply a coupon (optional)'}</p>
            </div>
            <div className='input-container'>
                <div className='input'>
                    <input type="text" placeholder={match.params.language === 'it' ? 'Inserisci un codice sconto' : 'Insert a coupon code'} name="coupon_code" className="coupon-input" value={couponValue} onChange={handleChange} />
                    <div className='button' onClick={applyCoupon}>{match.params.language === 'it' ? 'APPLICA' : 'APPLY'}</div>
                </div>
                {couponMessage &&
                    <p className='message'>{couponMessage}</p>
                }
            </div>
        </StyledCoupons>
    )
}

function mapStateToProps(state) {
    return {
        coupons: state.coupons
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setAppliedCoupon
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CouponsTab));