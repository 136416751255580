import React from 'react';
import styled from 'styled-components';

const StyledBlurpsBot = styled.section`
    position: relative;
    background-color: white;
    z-index: 10px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 50px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 50px;
    @media screen and (max-width: 500px) {
        flex-direction: column;        
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .blurp_bot {
        display: flex;
        width: 45%;
        padding-bottom: 50px;
        @media screen and (max-width: 650px) {
            padding-bottom: 30px;
        }
        @media screen and (max-width: 500px) {
            width: 100%;
        }
        div {
            padding-left: 10px;
            @media screen and (max-width: 500px) {
                padding-left: 25px;    
            }
        }
        img {
            height: 30px;
            @media screen and (max-width: 650px) {
                height: 25px
            }
        }
        h2 {
            font-size: 2.4rem;
            padding-bottom: 15px;
            @media screen and (max-width: 650px) {
                font-size: 1.8rem;
                padding-bottom: 10px;
            }
        }
        p {
            font-size: 1.6rem;
            line-height: 1.7;
            font-style: italic;
            @media screen and (max-width: 650px) {
                font-size: 1.4rem;
            }
        }
    }
`

const BlurpsBot = props => {
    const { category } = props;
    return (
        <StyledBlurpsBot>
            <div className='blurp_bot'>
                <img src={category.bot_blurp_img_1.guid} alt="" />
                <div>
                    <h2>{category.bot_blurp_title_1}</h2>
                    <p>{category.bot_blurp_description_1}</p>
                </div>
            </div>
            <div className='blurp_bot'>
                <img src={category.bot_blurp_img_2.guid} alt="" />
                <div>
                    <h2>{category.bot_blurp_title_2}</h2>
                    <p>{category.bot_blurp_description_2}</p>
                </div>
            </div>
            <div className='blurp_bot'>
                <img src={category.bot_blurp_img_3.guid} alt="" />
                <div>
                    <h2>{category.bot_blurp_title_3}</h2>
                    <p>{category.bot_blurp_description_3}</p>
                </div>
            </div>
            <div className='blurp_bot'>
                <img src={category.bot_blurp_img_4.guid} alt="" />
                <div>
                    <h2>{category.bot_blurp_title_4}</h2>
                    <p>{category.bot_blurp_description_4}</p>
                </div>
            </div>
        </StyledBlurpsBot>
    )
}

export default BlurpsBot;