import React from 'react';
import styled from 'styled-components'

const StyledTitle = styled.div`
    h2 {
        margin: 20px 0 50px 50px;
        font-size: 2.8rem;
        font-weight: bold;
        @media screen and (max-width: 1000px) {
            font-size: 2.2rem;
            margin-top: 0;
            margin-bottom: 30px;
            margin-left: 25px;
        }
    }
`

const Title = props => {
    const { language, productsNumber } = props;

    const plurals = () => {
        if (language === 'it') {
            if (productsNumber === 1) return 'articolo';
            return 'articoli';
        }
        if (productsNumber === 1) return 'article';
        return 'articles';
    }

    return (
        <StyledTitle>
            <h2>{language === 'it' ?
                `Carrello (${productsNumber} ${plurals()})` :
                `Cart (${productsNumber} ${plurals()})`}</h2>
        </StyledTitle>
    )
}

export default Title;