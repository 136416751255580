import React from 'react';
import styled from 'styled-components';

const Styled404 = styled.section`
    h1{
        text-align: center;
        font-size: 10rem;
        padding-top: 100px;
    }
    p{
        text-align: center;
        font-size: 4rem;
        padding: 100px;
    }
`

const Page404 = props => {
    return (
        <Styled404>
            <h1>404</h1>
            <p>The page you are looking for does not exist</p>
        </Styled404>
    )
}

export default Page404