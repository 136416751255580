import React, { useEffect } from 'react'
import styled from 'styled-components';

const StyledMailerLite = styled.section`
    min-height: 425px;
    .mailerlite {
        background-image: ${props => `url(${props.image})`};
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 30px 2%;
        min-height: 425px;
    }
`

const MailerLite = props => {
    useEffect(() => {
        const js = `
        (function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
            var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
            f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
            var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
            _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');
            
            var ml_account = ml('accounts', '2818420', 'q7g2e4d0m8', 'load');
      `;
      const script = document.createElement("script");
      const scriptText = document.createTextNode(js);
      script.appendChild(scriptText);
      document.body.appendChild(script);
  
      const script2 = document.createElement("script");
      script2.src = "https://static.mailerlite.com/js/w/webforms.min.js?CODE";
      document.body.appendChild(script2);
    }, [])
    return (
        <StyledMailerLite image='https://payments.yogicyantra.com/wp-content/uploads/2020/01/combo-shakti-shiva.jpg'>
            <div className="ml-form-embed mailerlite"
                data-account="2818420:q7g2e4d0m8"
                data-form="3562126:u8f9o4">
            </div>
        </StyledMailerLite>
    )
}

export default MailerLite;