import React from 'react';
import styled from 'styled-components';

const StyledBox = styled.article`
    width: 800px;    
    margin: 40px auto;
    border: 10px solid #5cb597;
    background-color: #5cb597;
    @media screen and (max-width: 1000px) {
        width: 500px;
    }
    @media screen and (max-width: 600px) {
        max-width: 100%;
    }
    h2{
        font-size: 3rem;
        text-align: center;
        padding: 15px 60px;
        color: black;
        line-height: 1.3;
        @media screen and (max-width: 1000px) {
            font-size: 2.5rem;
            padding: 15px 20px;
        }
        @media screen and (max-width: 600px) {
            font-size: 2rem;
            padding: 15px 10px;
        }
    }
    video{
        width: 780px;
        height: 710px;
        @media screen and (max-width: 1000px) {
            width: 480px;
            height: 440px;
        }
        @media screen and (max-width: 600px) {
            max-width: 100%;
            height: auto;
        }
    }
`

const VideoBox = props => {
    const {video, title} = props;
    return(
        <StyledBox>
            <h2>{title}</h2>
            <video  autoPlay muted controls loop playsInline>
                <source src={video.guid} type='video/mp4' />
                Your browser does not support the video tag
            </video>
        </StyledBox>
    )
}

export default VideoBox;