import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import uuid from 'uuid/v4'

const StyledRelatedProd = styled.section`
    font-size: 1.4rem;
    h4 {
        color: white;
        background-color: black;
        text-align: center;
        width: 100%;
        padding: 15px;
    }
    .related-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 40px 0;
    }
    .card {
        width: 20%;
        text-align: center;
        :hover {
            filter: saturate(100%) brightness(120%);
        }
        @media screen and (max-width: 850px) {
            width: 45%;
        }
        img {
            width: 100%;
        }
        .price {
                padding: 10px;
                display: flex;
                justify-content: center;
                span {
                    text-decoration-line: line-through;
                    padding-right: 5px;
                }
                p {
                    font-weight: 700;
                }
            }
    }
`

const RelatedProducts = props => {
    const { product, products, productPage, match, media } = props;
    return (
        <StyledRelatedProd>
            <h4>{productPage.related_title}</h4>
            <div className='related-container'>
                {product.related_ids.map(id => {
                    const product = Object.values(products.data).filter(prod => prod.id === id)[0]
                    return (
                        <Link key={uuid()} className='card' to={`/${match.params.language}/${product.slug}`}>
                            {media.data[product.images[0]] && <img src={products.images[product.images[0]].src} alt={match.params.language === 'it' ? media.data[product.images[0]].alternative_text_it : media.data[product.images[0]].alt_text} />}
                            <h5>{product.name}</h5>
                            <div className='price'>
                                {product.sale_price && <span>{product.regular_price}€</span>}
                                <p>{product.price} €</p>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </StyledRelatedProd>
    )
}

function mapStateToProps(state) {
    return {
        products: state.products,
        productPage: state.general.productPage,
        media: state.media
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RelatedProducts));