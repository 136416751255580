import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet'


const StyledPrivacy = styled.main`
    width: 80%;
    margin: 0 auto;
    h1 {
        font-size: 4rem;
        font-weight: bold;
        text-align: center;
        padding: 20px 0;
    }
    p, li {
        font-size: 1.6rem;
        padding: 12px 0;
        line-height: 1.5;
    }
    li {
        list-style-type: disc;
        list-style-position: inside;
    }
    h2 {
        font-size: 2.5rem;
        font-weight: bold;
        padding: 10px 0;
    }
    h3 {
        font-size: 2.5rem;
        padding: 10px 0;
    }
`

const PrivacyPage = props => {
    return (
        <StyledPrivacy>
            <Helmet>
                <title>Privacy - YogicYantra</title>
                <meta name="description" content='Check out our Privacy Policies' />
            </Helmet>
            <h1>PRIVACY POLICY</h1>
            <p>YogicYantra is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
            <p>We keep our privacy policy under regular review and we will place any updates on this webpage. This privacy policy was last updated on 14 September 2017.</p>
            <h2>WHAT WE COLLECT</h2>
            <p>We will collect information about you when you request a quotation or ask for further information regarding our products and services. The type of information will include:</p>
            <ul>
                <li>Name and date of birth</li>
                <li>Contact information including: address, postcode, telephone and email address</li>
                <li>​Possible health and fitness matters</li>
            </ul>
            <h2>WHAT WE DO WITH THE INFORMATION WE GATHER</h2>
            <p>We require this information to enable us to provide pricing and bespoke quotations for our services you asked for, and in particular for the following reasons:</p>
            <h3>INTERNAL RECORD KEEPING</h3>
            <p>If you agree, we may use information held about you, to provide you with information about other products and services which we and other Global Science companies offer, which we feel may be appropriate to you by email, telephone, post or other means.</p>
            <p>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone or post. We may use the information to customise the website according to your interests.</p>
            <h3>SECURITY</h3>
            <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
            <h3>COOKIES</h3>
            <p>The Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011 has changed and the rules are essentially designed to protect the privacy of internet users. For more information on the changes please visit the Information Commissioners Office Website.</p>
            <h3>GOOGLE ANALYTICS COOKIES AND RE-MARKETING</h3>
            <p>This website uses Google Analytics to help analyse how visitors use this site.</p>
            <p>Google Analytics uses “cookies”, which are small text files placed on your computer, to collect standard internet log information and visitor behaviour information in an anonymous form. Note the deliberate emphasis of anonymous, as no personally identifiable information is collected about you unless you explicitly submit that information via a fill-in form on this website. So if I need personal info from you – for example your email address to sign up to our newsletter, or to make an online purchase –YogicYantra will ask for it and you will have the option to provide it or not.</p>
            <p>The anonymous information generated by Google Analytics cookies about your use of this website.</p>
            <p>This information is then processed to compile statistical reports on website activity for www.yogicyantra.com.com</p>
            <p>We use these reports to evaluate aggregate visitor usage so that we can optimise the content, and its marketing, to better meet your future needs.</p>
            <p>We do not link, or seek to link, an IP address with the identity of a computer user. In fact, we will not associate any data gathered from this site with any personally identifiable information from any source, unless you explicitly submit that information via a fill-in form. Google will not associate your IP address with any other data held by Google.</p>
            <p>In summary, your anonymity is safe. That is YogicYantra commitment to you as a valued reader and potential customer.</p>
            <h2>HOW WE USE COOKIES</h2>
            <p>A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
            <p>We use traffic log cookies to identify records which pages are being used. This helps us analyse data about web page traffic as an anonymous aggregate and improve our website in order to tailor it to site visitor’s usage. Overall, cookies help us provide you with a better website, by enabling us to monitor which pages site visitors find most useful and improve upon the others pages. A cookie in no way gives us access to your computer or any personally identifiable information about you, other than the data you choose to share with us.</p>
            <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
            <h2>BEHAVIOURALLY TARGETED ADVERTISING COOKIES</h2>
            <p>YogicYantra uses first-party cookies for Google Analytics and may use third-party cookies for Google Adwords together to inform, optimise and serve ads based on site visitors past visits to your website.</p>
            <p>YogicYantra may use 3rd party cookies to serve you remarketing advertisements that we believe are relevant to your previous interests. For example, if you have visited our site and or purchased a product you could see an YogicYantra advertisement on other sites within the Google Display Network that you visit.</p>
            <p>However, we do not tell other sites who you are and we do not share your personal individual details. This is all managed through the Google Adwords network, where data shared with 3rd parties by Google. Member websites of the Google Display Network display ads based on aggregate data supplied to them by Google. See Google’s Interest-based privacy policy <a href="http://support.google.com/adwordspolicy/bin/answer.py?hl=en-GB&answer=143465&rd=1">for more details</a></p>
            <h2>CAN A WEBSITE USER OPT OUT OF GOOGLE RE-MARKETING COOKIES?</h2>
            <p>YogicYantra only uses the Google Display advertising network for re-marketing.</p>
            <p>Site users can opt out of Google Analytics for Display Advertisers and opt out of customised Google Display Network ads by visiting Google’s Ads Preferences Manager.</p>
            <p>Need more general information on the practise of re-marketing in the EU and America?</p>
            <p>Users of the worldwide web that are based in the UK and the rest of the European Union can visit&nbsp;<a href="http://www.youronlinechoices.eu/">www.youronlinechoices.eu</a>&nbsp;and users of the worldwide web based in the US can visit&nbsp;<a href="http://www.aboutads.info/choices">http://www.aboutads.info/choices</a></p>
            <p>The above websites are not associated with YogicYantra and we are not responsible for their content.</p>
            <h2>LINKS TO OTHER WEBSITES</h2>
            <p>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
            <h2>CONTROLLING YOUR PERSONAL INFORMATION</h2>
            <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
            <p>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at info@yogicyantra.com</p>
            <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
            <p>Your personal data will not be transferred to countries outside the EEA by YogicYantra. You have the right to access your personal information (subject to certain exemptions. If you would like a copy of the information held on you please write an email to the YogicYantra, Data Protection Officer at info@yogicyantra.com</p>
            <p>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible. We will promptly correct any information found to be incorrect.</p>
        </StyledPrivacy>
    )
}

export default PrivacyPage;