import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Header from "../components/homePage/Header";
import BlurpsTop from "../components/homePage/BlurpsTop";
import TopContent from "../components/shopNow/TopContent";
import ProductsCards from "../components/homePage/ProductsCards";
import TrustedBy from "../components/homePage/TrustedBy";
// import TrustBox from '../components/TrustBox';
import MailerLite from "../components/MailerLite";

const StyledHome = styled.main``;
const StyledContainer = styled.div`
  max-width: 1280px;
  width: 86%;
  margin: 0 auto;
  transition: 1s;
  #container {
    margin-bottom: 50px;
    height: 750px;
    overflow: hidden; /* if you don't want a scrollbar, set to hidden */
    overflow-x: hidden; /* hides horizontal scrollbar on newer browsers */
    @media screen and (max-width: 1156px) {
      height: 720px;
    }
    @media screen and (max-width: 907px) {
      height: 2200px;
    }
    @media screen and (max-width: 450px) {
      height: 2300px;
    }
    @media screen and (max-width: 450px) {
      height: 2500px;
    }
    h2 {
      font-size: 3.5rem;
      padding-top: 50px;
      text-align: center;
      padding-bottom: 50px;
    }
  }
  #embed {
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    height: 2500px; /* determines where the bottom of the page cuts off */
    margin-top: 0; /* clipping top of page */
    overflow: hidden;
    resize: none;
  }
`;

const StyledTrustPilot = styled.div`
  border-bottom: 1px solid #e2e2e2;
  padding: 40px 0;
  h2 {
    font-size: 3.5rem;
    padding-top: 50px;
    text-align: center;
    padding-bottom: 50px;
    @media screen and (max-width: 650px) {
      font-size: 2.6rem;
      font-weight: bold;
    }
  }
  .review-desktop {
    @media screen and (max-width: 650px) {
      display: none;
    }
  }
  .review-mobile {
    display: none;
    @media screen and (max-width: 650px) {
      display: block;
    }
  }
`;

const HomePage = (props) => {
  const { shopNow, language, homePage } = props;

  return (
    <StyledHome>
      <Helmet>
        <title>{homePage.meta_title}</title>
        <meta name="description" content={homePage.meta_description} />
        <meta property="og:title" content={homePage.meta_title} />
        <meta property="og:description" content={homePage.meta_description} />
        {homePage.meta_image && (
          <meta property="og:image" content={homePage.meta_image.guid} />
        )}
      </Helmet>
      <Header language={language} />
      <StyledContainer>
        <BlurpsTop />
        {Object.keys(shopNow).length > 0 && <TopContent language={language} />}
        <ProductsCards
          title="Best Sellers"
          tagSlug={"best_seller"}
          language={language}
        />
        {language === "it" ? (
          <StyledTrustPilot>
            <h2>Cosa dicono i nostri Clienti:</h2>
            {/* <TrustBox attributes={{
                                locale: "it-IT", template: "54ad5defc6454f065c28af8b", height: "240px", width: "100%", theme: "light", tags: "Generale", stars: "4,5"
                            }} /> */}
            <iframe
              title="reviews"
              className="review-desktop"
              id="AV_widget_iframe"
              frameBorder="0"
              width="100%"
              height="100%"
              src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbhorizontal_index.html"
            ></iframe>
            <iframe
              title="reviews"
              className="review-mobile"
              id="AV_widget_iframe"
              frameBorder="0"
              width="100%"
              height="1000px"
              src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbindex.html"
            ></iframe>
          </StyledTrustPilot>
        ) : (
          <StyledTrustPilot>
            <h2>What our Customers say:</h2>
            {/* <TrustBox attributes={{
                                locale: "en-GB", template: "54ad5defc6454f065c28af8b", height: "240px", width: "100%", theme: "light", tags: "eng", stars: "4,5"
                            }} /> */}
            <iframe
              title="reviews"
              className="review-desktop"
              id="AV_widget_iframe"
              frameBorder="0"
              width="100%"
              height="100%"
              src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbhorizontal_index.html"
            ></iframe>
            <iframe
              title="reviews"
              className="review-mobile"
              id="AV_widget_iframe"
              frameBorder="0"
              width="100%"
              height="1000px"
              src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbindex.html"
            ></iframe>
          </StyledTrustPilot>
        )}
        <TrustedBy language={language} />
        <ProductsCards
          title={language === "it" ? "Nuovi Arrivi" : "New Products"}
          tagSlug={"new_arrival"}
          language={language}
        />
        {language === "it" ? (
          // <StyledTrustPilot>
          //     <TrustBox attributes={{
          //         locale: "it-IT", template: "539adbd6dec7e10e686debee", height: "500px", width: "100%", theme: "light", tags: "Generale", stars: "4,5", language: 'it'
          //     }} />
          // </StyledTrustPilot>
          <StyledTrustPilot>
            <iframe
              title="reviews"
              className="review-desktop"
              id="AV_widget_iframe"
              frameBorder="0"
              width="100%"
              height="100%"
              src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbhorizontal_index.html"
            ></iframe>
            <iframe
              title="reviews"
              className="review-mobile"
              id="AV_widget_iframe"
              frameBorder="0"
              width="100%"
              height="1000px"
              src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbindex.html"
            ></iframe>
          </StyledTrustPilot>
        ) : (
          <StyledTrustPilot>
            {/* <TrustBox attributes={{
                                locale: "en-GB", template: "539adbd6dec7e10e686debee", height: "500px", width: "100%", theme: "light", tags: "eng", stars: "4,5", language: 'en'
                            }} /> */}
            <iframe
              title="reviews"
              className="review-desktop"
              id="AV_widget_iframe"
              frameBorder="0"
              width="100%"
              height="100%"
              src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbhorizontal_index.html"
            ></iframe>
            <iframe
              title="reviews"
              className="review-mobile"
              id="AV_widget_iframe"
              frameBorder="0"
              width="100%"
              height="1000px"
              src="//cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/widget4/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66dbindex.html"
            ></iframe>
          </StyledTrustPilot>
        )}
        <section id="container">
          <h2>{language === "it" ? "Per Approfondire.." : "Dive Into.."}</h2>
          <iframe
            title="blog"
            id="embed"
            scrolling="no"
            src={
              language === "it"
                ? "https://blog.yogicyantra.com/blog-per-react/"
                : "https://blog.yogicyantra.com/en/blog-per-react/"
            }
          ></iframe>
        </section>
      </StyledContainer>
      <MailerLite />
    </StyledHome>
  );
};

function mapStateToProps(state) {
  return {
    shopNow: state.general.shopNow,
    homePage: state.general.homePage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
