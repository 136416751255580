import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uuid from 'uuid/v4'
import styled from 'styled-components'

import { setVisibleCategories, setVisibleSubCategories, setVisibleGeneral, addGeneralFaq, addHomePage, addShopNow, addHeader, addProductPage, setVisibleReferralLP, setVisibleLeaflet, changeAddProdModal } from '../redux/actions/actionCreators'
import { cleanSlug } from '../helpers/cleanCategories'
import { getVisibleCategories } from '../helpers/getVisible'
import { findAttr, findFaq } from '../redux/helpers'

import HomePage from './HomePage'
import ShopNow from './ShopNow'
import CategoryPage from './CategoryPage'
import ProductPage from './ProductPage'
import Header from '../components/header/Header'
import Footer from '../components/Footer'
import ScrollToTop from '../components/ScrollToTop';
import Cart from '../components/UI/Cart';
import CartPage from './CartPage';
import PrivacyPage from './PrivacyPage';
import ScrollToTopUI from '../components/UI/ScrollToTopUI';
import FaqPage from './FaqPage'
import FreeShipping from '../components/UI/FreeShipping'
import Page404 from '../pages/Page404'
import CategoryLandingAds from '../pages/CategoryLandingAds'
import RefreshPage from './RefreshPage';
import AssistencePage from './AssistencePage';
import AssistanceShippingPage from './AssistanceShippingPage';
import AssistanceProductsPage from './AssistanceProductsPage';
import InfluencerReferralPage from './InfluencerReferralPage'
import HelpMeModal from '../components/UI/HelpMeModal';
import LeafletLandingPage from './LeafletLandingPage';


const StyledContainer = styled.div`
  .black-friday-banner{
    position: fixed;
    width: 387px;
    bottom: 50px;
    z-index: 15;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100px;
    background-image: url('https://payments.yogicyantra.com/wp-content/uploads/2021/11/Black-Friday-Label.png');
    @media screen and (max-width: 650px) {
      height: 65px;
    }
  }
`

const Home = props => {
  const { products, general, categories, subCategories, setVisibleGeneral, setVisibleCategories, setVisibleSubCategories, addGeneralFaq, addHomePage, addShopNow, addHeader, addProductPage, referralLP, setVisibleReferralLP , leaflet, setVisibleLeaflet, match, error } = props;
  const history = useHistory()
  const [lastURL, setLastURL] = useState('');
  useEffect(() => {
    if (match.params.language === 'it') {
      setVisibleCategories(getVisibleCategories(categories, 'it'))
      setVisibleSubCategories(getVisibleCategories(subCategories, 'it'))
      setVisibleReferralLP(getVisibleCategories(referralLP, 'it'))
      setVisibleLeaflet(getVisibleCategories(leaflet, 'it'))
      setVisibleGeneral('general-it')
      if (general.data[general.visibles]) {
        addGeneralFaq(findFaq(general.data[general.visibles], 'faq_'))
        addShopNow(findAttr(general.data[general.visibles], 'shop_now_'))
        addHomePage(findAttr(general.data[general.visibles], 'homepage_'))
        addHeader(findAttr(general.data[general.visibles], 'header_'))
        addProductPage(findAttr(general.data[general.visibles], 'product_page_'))
      }
    } else {
      setVisibleCategories(getVisibleCategories(categories, 'en'))
      setVisibleSubCategories(getVisibleCategories(subCategories, 'en'))
      setVisibleReferralLP(getVisibleCategories(referralLP, 'en'))
      setVisibleLeaflet(getVisibleCategories(leaflet, 'en'))
      setVisibleGeneral('general')
      if (general.data[general.visibles]) {
        addGeneralFaq(findFaq(general.data[general.visibles], 'faq_'))
        addShopNow(findAttr(general.data[general.visibles], 'shop_now_'))
        addHomePage(findAttr(general.data[general.visibles], 'homepage_'))
        addHeader(findAttr(general.data[general.visibles], 'header_'))
        addProductPage(findAttr(general.data[general.visibles], 'product_page_'))
      }
    }
    // eslint-disable-next-line
  }, [general.visibles, general.data, error])

  useEffect(() => {
    if (!error && history.location.pathname !== ('/en/error' || '/it/error')) {
      setLastURL(history.location.pathname)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <StyledContainer  >
      {/* <div className='black-friday-banner'></div> */}
      {error && <Redirect from={history.location.pathname} to={`/${match.params.language}/error`} />}
      <ScrollToTop />
      {Object.keys(general.data).length > 0 && <FreeShipping />}
      <Header language={props.match.params.language} />
      <Cart />
      <HelpMeModal language={props.match.params.language} />
      <Switch>

        <Route exact path='/it' render={props => <HomePage {...props} language='it' />} />
        <Route exact path='/en' render={props => <HomePage {...props} language='en' />} />
        <Route path='/:language/shop_now' render={props => <ShopNow {...props} />} />
        <Route path='/:language/cart' render={props => <CartPage {...props} />} />
        <Route path='/:language/privacy' render={props => <PrivacyPage {...props} />} />
        <Route path='/:language/faq' render={props => <FaqPage {...props} />} />
        <Route path='/:language/assistance' render={props => <AssistencePage {...props} />} />
        <Route path='/:language/assistance_shipping' render={props => <AssistanceShippingPage {...props} />} />
        <Route path='/:language/assistance_products' render={props => <AssistanceProductsPage {...props} />} />
        <Route path='/:language/referral' render={props => <InfluencerReferralPage {...props} />} />
        <Route path='/:language/leaflet' render={props => <LeafletLandingPage {...props} />} />
        <Route path='/:language/error' render={props => <RefreshPage {...props} lastURL={lastURL} />} />
        {categories.visibles.length &&
          categories.visibles.map(slug => (
            <Route key={uuid()} path={`/:language/${cleanSlug(slug)}`} render={props => <CategoryPage language={props.match.params.language} category={categories.data[slug]} {...props} />} />
          ))}
        {categories.visibles.length &&
          categories.visibles.map(slug => (
            <Route key={uuid()} path={`/:language/landing/${cleanSlug(slug)}`} render={props => <CategoryLandingAds category={categories.data[slug]} {...props} />} />
          ))}
        {products.slugList &&
          products.slugList.map(slug => (
            <Route key={uuid()} path={`/:language/${slug}`} render={props => <ProductPage product={products.data[slug]} {...props} />} />
          ))}
        <Route path='/en/404' component={Page404} />

        <Redirect from='/it/prodotto/yoni-egg-di-giada/' to='/it/jade-yoni-egg' />
        <Redirect from='/it/prodotto/yoni-egg-di-quarzo-rosa/' to='/it/rose-quartz-yoni-egg' />
        <Redirect from='/it/yoni-egg/' to='/it/yoni-eggs/' />
        <Redirect from='/it/prodotto/balance-mala/' to='/it/balance-mala-beads' />
        <Redirect from='/it/prodotto/set-di-yoni-egg-di-giada/' to='/it/set-of-3-jade-yoni-eggs' />
        <Redirect from='/it/prodotto/yoni-egg-starting-kit-giada/' to='/it/yoni-egg-starting-kit-jade' />
        <Redirect from='/it/prodotto/kundalini-shakti-mat/' to='/it/kundalini-shakti-mat' />
        <Redirect from='/it/prodotto/set-di-3-yoni-egg-di-quarzo-rosa/' to='/it/set-of-3-rose-quartz-yoni-eggs' />
        <Redirect from='/it/shop-now/' to='/it/shop_now' />
        <Redirect from='/it/prodotto/yoni-egg-starting-kit-giada-bianca/' to='/it/yoni-egg-starting-kit-white-jade' />
        <Redirect from='/it/prodotto/yoni-egg-starting-kit-set-di-quarzo-rosa/' to='/it/yoni-egg-starting-kit-rose-quartz' />
        <Redirect from='/it/prodotto/yoni-egg-di-ametista/' to='/it/amethyst-yoni-egg' />
        <Redirect from='/it/prodotto/strength-mala-beads/' to='/it/strength-mala-beads' />
        <Redirect from='/it/mala/' to='/it/mala-beads' />
        <Redirect from='/it/prodotto/cosmic-mat/' to='/it/cosmic-mat' />
        <Redirect from='/it/prodotto/compassion-mala/' to='/it/compassion-mala-beads' />
        <Redirect from='/it/prodotto/holy-ganesh-mat/' to='/it/holy-ganesh-mat' />
        <Redirect from='/it/prodotto/inner-peace-mat/' to='/it/inner-peace-mat' />
        <Redirect from='/yoni-egg/' to='/it/yoni-eggs' />
        <Redirect from='/yoga-donna/' to='/it/' />
        <Redirect from='/it/prodotto/wisdom-mala/' to='/it/mala-beads/' />
        {products.slugList.length > 0 && categories.visibles.length > 0 && <Route render={() => <Redirect to='/en/404' />} />}
      </Switch>
      <Footer language={props.match.params.language} />
      
      {/* script prodotti recensioni verificate */}
      {/* <script charSet="utf-8" src="https://cl.avis-verifies.com/it/cache/8/a/b/8ab4b56f-7f7f-3b04-1d2d-b2d14b0f66db/AWS/PRODUCT_API/tag.min.js"></script> */}
      
      
      <ScrollToTopUI />
    </StyledContainer>
  )
}

function mapStateToProps(state) {
  return {
    products: state.products,
    categories: state.categories,
    subCategories: state.subCategories,
    general: state.general,
    error: state.fetching.error,
    referralLP: state.referralLP,
    leaflet: state.leaflet
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setVisibleCategories,
      setVisibleSubCategories,
      setVisibleGeneral,
      addGeneralFaq,
      addHeader,
      addShopNow,
      addHomePage,
      addProductPage,
      setVisibleReferralLP,
      setVisibleLeaflet,
      changeAddProdModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)