import React from 'react';
import styled, { keyframes } from 'styled-components';

import logo from '../assets/logo.png'

const pulse = keyframes`
  0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}
`

const StyledLoader = styled.img`
  display:block;
  margin: 300px auto 40px auto;
	transform: scale(1);
	animation: ${pulse} 1s infinite;
`

const StyledMessage = styled.h1`
  font-size: 3rem;
  text-align: center;
  color: black;
  margin-bottom: 250px;
`

const Loader = props => {
    const {language, page} = props;
    const messages = {
      it : {
        fetching: 'Qui e Ora..',
        product: 'Sto meditando sul tuo prodotto..',
        cart: 'Sto meditando sul tuo carrello..',
      },
      en : {
        fetching: 'Be Here and Now..',
        product: 'Meditating on your product..',
        cart: 'Meditating on your cart..',
      }
    }

    return (
        <div className="App">
            <StyledLoader src={logo} alt='logo' />
            <StyledMessage>{messages[language][page]}</StyledMessage>
        </div>
    )
}

export default Loader;